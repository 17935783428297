export const en_lang = {
  "data": {
    "detailsOfCalculation.fields.additions": "Surcharges",
    "crudForm.field.forHour": "per hour",
    "crud.field.mainPortId.title": "Terminal of the port",
    "form.field.organization.factAddress": "Actual address",
    "form.field.commission.container.price": "Per Container",
    "crud.field.billing.relative": "Relative",
    "form.field.iAm": "I am",
    "form.field.addDocument": "Add document",
    "crud.field.organizationClient.title": "Organization (client)",
    "reports.types.ORDER_REQUEST_COUNT": "Number of orders processed",
    "orderCalculation.hidePriceLabelHelp": "The system found several shipping options for you. Log in / Register to view the details.",
    "organizationsProfiles.status.REJECTED": "Rejected",
    "profile.data.title": "Your Profile",
    "form.order.contactPerson": "Contact person",
    "modal.changePassword": "Password change form",
    "subscription.switchToFree.text": "Confirm the switch to the free subscription. Unused balance of available quotations will be cancelled.",
    "orderForm.modal.driverName": "Driver's name",
    "crudForm.field.planeType": "Aircraft type",
    "crud.field.showDetails": "Show details",
    "crudForm.field.id": "ID",
    "crud.form.view.title": "View entry",
    "crud.field.departurePlace.button.add": "Add shipper details",
    "crud.field.passwordReset.title": "Reset password",
    "detailsOfCalculation.callManagerResponse.title": "Request has been sent",
    "detailsOfCalculation.services_destination": "Destination port charges",
    "crud.form.add.template.title": "Create new template",
    "detailsOfCalculation.fields.integratorReward": "Forwarder's commission",
    "crud.field.international.title": "International",
    "reports.barChart.LANGUAGE": "Languages",
    "reports.statusTest.empty": "Data missing",
    "logistBlock.departureOrIssueDate": "Departure",
    "constants.franchiseTypes.percent": "Percent",
    "constants.typeOfRoadGeneral.zip": "for ZIP codes",
    "crud.field.surchargePerItem.title": "Price for next units of cargo.",
    "orderForm.idBolOrAwb": "Bill of lading:",
    "form.error.commission.alreadyExist": "Comission for this transportation type has already been set",
    "form.order.cargoDescription": "Cargo description",
    "detailsOfCalculation.fromDoor": "from door",
    "constants.month.february": "February",
    "ordersList.button.getMinInfo": "Display in a brief form",
    "operatorAdmin.listOfAPIs": "External APIs",
    "modal.orderInfo.id": "ID",
    "report.unit.lb": "lb",
    "crud.tab.cargoParams": "Quotes",
    "crud.field.fromHub.title": "From",
    "detailsOfCalculation.customsHouseTitle": "Duties and VAT",
    "crud.field.service.title": "Type of service",
    "palletTariff.form.defaultFileName": "Trucking charges tariff",
    "service.serverIsUnavailable": "Server is not available now",
    "customsOfCargo.form.calcBtn": "Calculate",
    "common.documents.error.fields": "Fill in all the fields and upload the file",
    "crudForm.field.rateBilling": "Rate",
    "error.numberAccount.size.20": "Enter the correct account number (20 digits)",
    "partner.rates.private": "Private",
    "subscription.tariff.current": "Current",
    "form.field.commission.integrator.percent": "% of total cost",
    "crud.field.updateDate.title": "Updated At",
    "orderForm.homeBill": "House Bill of Lading:",
    "report.unit.kg": "kg",
    "detailsOfCalculation.filters.sortFor": "Sort",
    "crud.add.changeParams": "Change parameters",
    "detailsOfCalculation.railway_station_destination": "Destination station charges",
    "crudForm.dictionary.field.compartmentSize": "Size of the compartment, (L, W, H)",
    "crud.field.departurePlace.button.delete": "Delete shipper details",
    "reportResource.title": "Report on the spent resources",
    "common.copy.success": "Data copied to clipboard",
    "form.field.countryFrom": "Country (from)",
    "crud.field.executionTime.title": "Execution time",
    "form.field.perKM": "Price per km",
    "customsOfCargo.rate.both": "Customs duty is charged at a combined rate of {mainRate}% оof the customs valuation plus {value} {currency}/{unit}. VAT {nds}%",
    "notifications.readAll": "Mark all as read",
    "mainMenu.subscriptions.tariff": "Subscription plans",
    "crudForm.field.hourly": "per hour",
    "crud.field.tarificationType.title": "Billing",
    "reportResource.report.avg.countExternalVariants": "Average number of calculation options including external partners",
    "crudForm.field.documentDeadline": "Deadline for submission of documents",
    "person.wizard": "New rate calculation",
    "crud.field.error.category.serviceExists": "The category can't be deleted as it contains services ",
    "crud.remove.failed": "An error occurred while deleting.",
    "ru.cargobar.imports.tariff.ContainerSeaFreightTariffImportServic.demurrage": "Invalid demurrage period. Row {0}, column {1}.",
    "general.cargo.compound.generalBtn": "Less-than-container/truckload cargo",
    "crudForm.dictionary.field.maxPalletCount": "Max Pallet Count",
    "deliveryOrders.orderList.headerTable.departurePoint": "From",
    "partner.rates.sea": "Sea freight",
    "detailsOfCalculation.dayRP": "days",
    "crud.field.allPorts": "All ports",
    "common.equalsfortariffAddress": "Wrong choice of path, according to the selected tariffing",
    "deliveryOrders.orderInfo.index": "Postal code",
    "reports.types": "Types of reports",
    "customsOfCargo.fields.price": "Cargo value",
    "crud.field.documentDeadline.title": "Deadline for submission of documents",
    "partner.userInfo.form.email.placeholder": "Enter email address",
    "crudForm.field.id.placeholder": "Not filled",
    "person.calculationInfo.fail": "No calculations found",
    "common.documents.position": "Position",
    "crud.field.actions.title": "Actions",
    "reports.lineChart.countOfAllClient": "Number of others",
    "report.unit.in": "in",
    "crudForm.field.fromAirport": "From/to airport",
    "wizard.cargo.form.size.length": "Length",
    "crud.field.geoObjects.title": "Geo objects",
    "operatorAdmin.directories.vehicleDisclaimers": "Trucking charges terms and conditions",
    "indicator.pointOfDestination.arrival.fact": "Point Of Destination, arrival (fact)",
    "modal.contactUs.successBody": "Thank you, we will contact you shortly.",
    "orderForm.modal.stateTruckNumber": "Truck plate number:",
    "crudForm.rates.title": "Add/edit rates",
    "partner.deliveryForClients": "Orders",
    "crud.field.palletType.title": "Pallet type",
    "register.title": "Registration",
    "form.field.commissionCurrency": "Forwarder's commission currency",
    "form.field.organization.factAddress.placeholder": "Enter actual address",
    "detailsOfCalculation.orderTable.typeOfCargo": "Cargo type",
    "form.field.customsClearance": "Customs clearance",
    "crud.uploadFromExcel.example.body.text": "All columns form must be filled!",
    "userRegistration.passwordDontMatch": "Entered passwords don't match",
    "common.invalidRange": "Invalid range",
    "crudForm.field.common.documents.type": "Type",
    "orderForm.actTransitTime": "Transit Time/ Actual:",
    "orderForm.numberOfPositions": "Items:",
    "form.order.truckType": "Truck type",
    "subscription.history.text": "Subscription track record:",
    "crudForm.field.currency": "Currency",
    "orderForm.modal.driver": "Driver:",
    "crudList.empty": "The list is empty",
    "dialog.confirm.title": "Question",
    "crud.field.dateRange.title": "Validity",
    "indicator.pointOfOrigin.arrival.fact": "Point Of Origin, arrival (fact)",
    "common.roleClient": "User",
    "agreement.agreeMsg": "You have confirmed that you have read and accepted all the paragraphs of this Agreement ",
    "orderForm.modal.cargoParameters": "Cargo parameters",
    "constants.placeTypes.railwayHub": "Railway hub",
    "logistBlock.arrivalOrInnings": "Arrival",
    "orderForm.modal.truckNumber": "Truck plate number:",
    "indicator.pointOfArrival.departure.plan": "Point Of Arrival, departure (plan)",
    "orderForm.companyName": "Company",
    "orderList.StatusColumnTitle": "Status",
    "orderForm.warning.message": "Your order has been created! You should fill in the details of the shipper, the consignee and the notified party. To do so, you can use the links:",
    "crud.field.sellerRewardMax.title": "Seller Reward Max",
    "crudForm.bulkOperations.nameCode.changeOwner": "Change owner",
    "crud.field.error.seaPort.freightTariffExists": "Unable to delete a port as the system includes its sea freight tariffs",
    "common.documents": "Documents",
    "crud.field.sort.title": "Sort Number",
    "modal.save.calculation.title": "Save the quotation",
    "detailsOfCalculation.OrderPopover": "We do not ship personal belongings or cargo from private individuals.",
    "report.transportation.avia": "air transportation",
    "form.wizard.title.from": "From",
    "logistBlock.invoicePaymentDate": "Invoice payment date",
    "crud.field.cityId.title": "City",
    "operatorAdmin.directories.seaPortHubs": "Sea Ports",
    "report.place.pieces": "pcs",
    "login.exit": "Login",
    "form.wizard.pickup": "Pick-up point",
    "crud.form.view.template.title": "View template",
    "modal.orderInfo.cargo": "Cargo",
    "report.unit.ft": "ft",
    "wizard.cargo.form.containerTypes": "Container type",
    "contacts.consignee": "Consignee",
    "form.order.remarks": "Remarks",
    "deliveryOrders.listItem.amount": "Quantity",
    "constants.month.may": "May",
    "partner.button.save": "Save Changes",
    "common.field.quotes": "quotes",
    "detailsOfCalculation.fields.totalCost": "Total cost",
    "indicator.customsClearance.departure.plan": "Customs Clearance, departure (plan)",
    "crudForm.field.customsClearance": "Customs Clearance",
    "orderForm.userName": "Name",
    "ru.cargobar.imports.tariff.ContainerSeaFreightTariffImportServic.currency.not.found": "There is no currency in the system. Row {0}, column {1}.",
    "crudForm.field.clearing": "Clearing",
    "detailsOfCalculation.dayMn": "days",
    "operatorAdmin.directories.services": "Services",
    "orderForm.numberOfPlaces": "Total pieces of outer packing:",
    "crud.field.transitHub.title": "Transit hub",
    "crudForm.dictionary.field.mainHatchSize": "Dimensions of the cargo hatch",
    "operatorAdmin.directories.consolidators": "Coloaders",
    "orderForm.from": "Point of departure:",
    "error.inn.size.10": "Enter the correct TIN (10 digits)",
    "operatorAdmin.countryCategories": "Country categories",
    "client.successCreate": "Client's profile was created",
    "ru.cargobar.imports.tariff.ContainerSeaFreightTariffImportServic.date.from": "The wrong date of the tariff. Row {0}, column {1}.",
    "trunking.form.errorsTitle": "Problem while downloading the tariffs:",
    "reports.field.btn.download": "Download",
    "crudForm.dictionary.field.maxWeight.placeholder": "Max weight",
    "form.order.grossWeight": "Gross weight",
    "constants.commissionTypes.priceRange": "Price range",
    "crudForm.field.intraportForwarding.container.to": "to",
    "detailsOfCalculation.weight": "weight",
    "client.passwordChanged": "Password was changed",
    "form.field.notificationOnTariffChange": "Subscription for tariff changes",
    "crud.field.organization.name.title.my": "My company ",
    "partner.bankDetails.form.field.numberAccount": "Account number",
    "trunking.form.downloadBtn": "Download",
    "crudForm.field.required": "Required",
    "common.invalidEmail": "Invalid email",
    "modal.orderInfo.template": "Template",
    "crud.field.airBillRate": "Cost per AWB",
    "customsOfCargo.fields.totalCost": "Total",
    "operatorAdmin.reports": "Quotation statistics",
    "reports.lineChart.countOfNew": "New",
    "partner.bankDetails.form.field.corrAccount.placeholder": "Enter the corresponding account number",
    "detailsOfCalculation.callManager.modalFailBody": "Couldn't send the message. Please try again later.",
    "detailsOfCalculation.dayImP": "day",
    "reports.lineChart.countOfAll": "All",
    "crudForm.field.countries": "Countries",
    "page.accessDenied.text": "You do not have authorised access to this page",
    "detailsOfCalculation.customsHouseBtn": "Customs calculator",
    "user.passwordRestored": "Password restored successfully",
    "crudForm.dictionary.field.maxCommercialDownload": "Max. commercial download, (kg)",
    "report.transportation.express": "express transportation",
    "crud.field.passwordReset.label": "Reset password",
    "operatorAdmin.listOfAPIs.connect": "Connect",
    "partner.rates.roadTransportation": "Origin trucking rates (full and part loads)",
    "wizard.cargo.form.size.width.placeholder": "W",
    "partner.orders": "Orders",
    "report.cargo.general": "LCL",
    "crud.filter.modifier": "Type",
    "general.oversized.cargo": "Oversized/ overweight cargo –  not suitable for standard equipment – our service department will contact you shortly to discuss the best options.",
    "orderForm.shipper": "Shipper:",
    "crud.field.considerFailedRequests.title": "Include responses with no result",
    "crud.field.railwayStation.title": "Railway station",
    "crud.field.ratingReportBtn.title": "View the report",
    "palletTariff.form.uploadDesc": "The file must be in .xlsx format",
    "crudForm.field.ADR_RAILWAY": "Address ⇄ Railway",
    "detailsOfCalculation.filters.sort.cost": "Price",
    "crud.field.demurrage.title": "Demurrage",
    "deliveryOrders.orderList.searchPlaceholder": "Enter the booking number or the cargo reference",
    "deliveryOrders.listItem.orderDate": "Order Date",
    "form.field.companyName.placeholder": "Enter company name",
    "operatorAdmin.directories.typesOfGoods": "Containers",
    "detailsOfCalculation.fields.commission": "Forwarder's commission",
    "wizard.cargo.form.consolidatedCargo.add": "Add",
    "form.field.commission.sea_transportation.menu": "Sea transportation",
    "operatorAdmin.directories.hubs": "Hubs",
    "form.field.organizationName.placeholder": "Enter company name",
    "crud.field.priceFirst.title": "Price for first unit of cargo.",
    "crudForm.field.conversionPercent": "Conversion Percent",
    "crud.filter.to": "To",
    "HOME_RAILWAY_WITHOUT_SYMBOL": "House Railway Bill",
    "report.unit.сm": "cm",
    "crudForm.field.tariffStatus": "Status",
    "notifications.noNotifications": "No notifications",
    "subscription.page.config.title": "Configuration of the subscription system",
    "reportResource.filter.period": "Report period:",
    "site.footer.menu.personalArea": "Personal Area",
    "wizard.cargo.form.palletTypes.placeholder": "Pallet",
    "crud.field.billing.absolute": "Absolute",
    "crud.field.categoryId.title": "Category",
    "crud.uploadFromExcel.example.body.btn.download": "Download",
    "importTranslates.alertNotSelectTranslation": "Please select at least one translation column",
    "operatorAdmin.deliveryForClients": "Orders",
    "form.field.notificationLimitScan": "Warning! If you change the period for collecting statistics for popular destinations, the changes will take effect within 24 hours.",
    "partner.commission.empty": "Forwarder's commission for selected profiles is missing",
    "person.detailsOfCalculation.fail": "Failed to receive order data",
    "report.transportation.complex": "complex transportation",
    "constants.modifier.private": "Private",
    "crud.field.error.railwayOperator.railwayTariffExists": "Railway operator can't be deleted as railway station costs for them are in the system",
    "constants.insuranceTransportType.sea": "Sea",
    "crudForm.bulkOperations.profile.error": "Organization doesn't have a necessary profile",
    "common.required.privateRanges": "Please add 1 range for a private rate",
    "crudForm.button.cancel": "Cancel",
    "crudForm.field.portsOfDeparture": "From",
    "form.field.maxKM": "Max. distance",
    "constants.month.november": "November",
    "detailsOfCalculation.fields.showTariffDetails": "Show details",
    "wizard.tooltip.use40Container": "We recommend considering FCL shipment for the size of your cargo.",
    "common.documents.actionsTitle": "Actions",
    "partner.profile": "Profile",
    "form.field.password.placeholder": "Enter password",
    "directionOfTransportation.departure": "Departure",
    "wizard.subscribe.message.first": "Subscribe to biweekly newsletters from Agorafreight.com to stay tuned on the new actual rates and updated tariffs.",
    "deliveryOrders.cargoInformation.cargoTypeTitle": "Cargo Information",
    "crud.field.countVariantsExternalCalculation.title": "Number of calculation options including external partners",
    "detailsOfCalculation.filters.sort.time": "Estimated transit time",
    "crudForm.field.customsPost": "Customs Post",
    "form.order.netWeight": "Net weight",
    "crudForm.field.maxHeight": "Max height",
    "crudForm.field.maxWeight": "Max weight",
    "constants.charging.PORT_RAILWAY": "Port ⇄ Railway",
    "reports.types.ORGANIZATION_COUNT": "Number of partners with tariffs submitted",
    "crudForm.field.toHub": "To",
    "auth.success.message": "Authorization is successful",
    "detailsOfCalculation.filters.sort.maxNumber": "Maximum number",
    "mainMenu.subscriptions": "Subscriptions",
    "crud.field.error.organization.orderRequestExists": "Uanble to delete a company who has users in the system",
    "crud.field.name.title": "Name",
    "site.leftMenu.link.contactUs": "Contact us",
    "crudForm.dictionary.currencies.title": "Currency",
    "crud.field.order.id.title": "Quotation number",
    "form.field.status": "Status",
    "form.field.private": "Data processing",
    "common.documents.sendEmailActionTitle": "Send e-mail",
    "crud.field.generalCargoRangeRate.title": "Price range",
    "crudForm.field.nds.ndsInc": "Included in the tariff",
    "form.order.palletsNumber": "Number of pallets",
    "detailsOfCalculation.filters.totalCount": "Results",
    "site.footer.subscribe.placeholder": "E-mail",
    "HOME_AWB": "House AWB:",
    "operatorAdmin.directories.seaLines": "Shipping Lines",
    "ru.cargobar.imports.tariff.ContainerSeaFreightTariffImportServic.port.to.not.found": "Such arrival port is not available in the system. Row {0}, column {1}.",
    "modal.changePassword.buttonSave": "Save",
    "deliveryOrders.cargoTracking.table.conversion": "Conversion rate",
    "user.invalidOldPassword": "Invalid old password",
    "crud.field.cargoContainerRates.title": "Container type",
    "person.calculationInfo.tryWithOtherData": "Sorry, no rate found.{br}At the moment the platform is operating  in test mode with a limited functionality, and supports only China - Russia traffic.{br}Please send us your inquiry and we will arrange a quotation.",
    "crudForm.field.period.on.15d": "15 days",
    "partner.bankDetails.form.field.address.placeholder": "Enter legal address in full",
    "deliveryOrders.cargoInformation.cargoTypeBox": "Cartons",
    "orderForm.modal.comment": "Comment",
    "importTranslates.titleResult": "Translation import result",
    "crud.disclaimers.vehicle.cargoType": "Cargo type",
    "form.field.container": "Container",
    "wizard.cargo.form.amount": "Quantity (pcs)",
    "form.field.address.to.placeholder": "To (address or zip) ...",
    "crud.filter.expiredTariffsParam": "Filter by expired tariffs",
    "modal.contactUs.sendBtn": "Send",
    "detailsOfCalculation.logIn": "log in",
    "partner.geography.form.address": "Address",
    "crudForm.field.minTransitPriceCost": "Min. transit cost",
    "customsOfCargo.rate.advalorem": "Customs duty is charged at a rate of {mainRate}% of the customs valuation. VAT {nds}%",
    "modal.orderInfo.shippingDate": "Shipping date",
    "form.field.middleName": "Middle Name",
    "login.submit.vk": "Login with VK",
    "crudForm.dictionary.field.floorSize": "Floor area, (sq. m.)",
    "detailsOfCalculation.tariffOrganization": "Organization",
    "modal.tracking.btn": "Tracking",
    "form.field.countryTo": "Country (to)",
    "reports.field.btn.downloadCsv": "download as .csv",
    "ru.cargobar.imports.tariff.ContainerSeaFreightTariffImportServic.detension": "Invalid detention period. Row {0}, column {1}.",
    "form.select.noResultText": "Nothing found",
    "subscription.tariff.create.request.confirm.text": "Your request will be processed soon.",
    "crud.field.arrivalData.title": "Consignee",
    "form.field.numberAccount": "Invoice number",
    "crud.field.hasCustoms.title": "Customs clearance",
    "subscription.modal.add.btn": "Subscribe",
    "crud.filter.field": "Field",
    "reports.barChart.TRANSPORT_HUB": "Transport hubs",
    "deliveryOrders.status.ARRIVED_TO_DESTINATION_HUB": "Arrived to destination HUB",
    "indicator.pointOfArrival.departure": "Point Of Arrival, departure",
    "operatorAdmin.charts": "Quotation statistics",
    "widget.order.getintouch": "You will be contacted shortly by a member of our team who will make all the necessary arrangements.",
    "crudForm.field.stations": "Stations",
    "agreement.modal.btnAgree": "Accept",
    "crud.field.commission.title": "Forwarder's commission",
    "form.order.shipper": "Shipper",
    "report.transportation.sea": "sea transportation",
    "wizard.button.reset": "Reset",
    "detailsOfCalculation.successOrder.body": "Thank you for order! Your order number - {orderId}.{br}Our specialist will contact you as soon as possible. Usually it happens in 15 minutes.",
    "subscription.page.title": "My subscription",
    "crud.filter.no": "No",
    "importTranslates.name.code": "Code",
    "modal.changePassword.password.placeholder": "Enter new password",
    "notifications.type.ExpiredCalculation.link.title": "Details",
    "crudForm.field.customsClearance.placeholder": "Enter price",
    "indicator.pointOfPickup.departure.plan": "Point Of Pickup, departure (plan) ",
    "detailsOfCalculation.transitTime": "Estimated transit time",
    "detailsOfCalculation.callManager.checkPriceBtn": "Clarify the rate",
    "crudForm.dictionary.field.code_currency.placeholder": "Enter the currency code",
    "form.field.lastName": "Last Name",
    "crud.field.country.to.title": "Country to",
    "modal.tracking.title": "Tracking",
    "subscriptionRequest.title": "Clients requests for subscription",
    "TTH_WITHOUT_SYMBOL": "Transport document:",
    "crud.field.cargo.title": "Cargo details",
    "crudForm.field.period.on.12m": "One year",
    "constants.nds.none": "Without VAT",
    "detailsOfCalculation.cargoIsOverSize.bot": "Please click Send, as we now have all the required data in our system, and we will provide a quotation as a priority by e-mail.",
    "ru.cargobar.imports.tariff.ContainerSeaFreightTariffImportServic.sealine.empty": "Invalid shipping line code. Row {0}, column {1}.",
    "detailsOfCalculation.updateCalculation": "Recalculate",
    "register.partner.title": "Register as a partner",
    "crudForm.field.fromHub": "From",
    "operatorAdmin.directories.ports": "Ports",
    "crud.field.ON_FREIGHT": "For freight",
    "form.field.confirmPassword.placeholder": "Confirm password",
    "ru.cargobar.imports.tariff.ContainerSeaFreightTariffImportServic.currency.empty": "There is no currency. Row {0}, column {1}.",
    "detailsOfCalculation.createDateNew": "as of",
    "common.equalsroad": "Chosen the same way",
    "crudForm.field.sum": "Amount",
    "deliveryOrders.cargoInformation.cargoTypePackage": "Packages",
    "crud.field.forwardRates.title": "Price range",
    "organizationsProfiles.title": "Companies profiles",
    "reportResource.report.avg.countVariants": "Average number of calculation options",
    "crud.field.number": "Number",
    "form.field.periodStart": "Period (from)",
    "crud.add.record": "Add new",
    "partner.generalInformation.form.field.profileStatus.REJECTED": "Rejected",
    "crudForm.field.intraportForwarding.container.price": "Per container",
    "crud.disclaimers.vehicle.hubName": "Hub",
    "detailsOfCalculation.fields.general.air": "Air shipment",
    "partner.geography.geoTypes.city": "City",
    "subscription.page.config.resetFree.value.DAY": "Day",
    "detailsOfCalculation.successOrder.title": "Your order has been created",
    "indicator.customsClearance.arrival": "Customs Clearance, arrival",
    "detailsOfCalculation.priceOverTime.client": "The price indicated is the minimum for ({minHour} {hour}), thereafter the hourly payment is applied",
    "common.error": "Error",
    "orderForm.currentStatus": "Current status:",
    "crud.field.dateTo.title": "Date to",
    "detailsOfCalculation.fields.cost": "Cost",
    "operatorAdmin.directories.vehicleTypes": "Vehicle types",
    "detailsOfCalculation.makeOrderConfirmation": "Are you sure you want to make an order?",
    "detailsOfCalculation.filters.success.text": "Data was successfully sorted",
    "mainMenu.reportResource": "Report on the spent resources",
    "ru.cargobar.imports.tariff.ContainerSeaFreightTariffImportServic.freightTerms.wrong": "Wrong freight terms value. Row {0}, column {1}.",
    "form.order.address": "Company address",
    "report.transportation.multimodal.sea": "multimodal transportation (sea + road)",
    "tracking.fields.date": "Date",
    "detailsOfCalculation.hourImP": "hour",
    "partner.userInfo.form.middleName": "Middle Name",
    "crudForm.bulkOperations.success": "Entries changed",
    "wizard.general.weight": "Weight",
    "crud.field.service": "Service",
    "auth.alreadySuccess.message": "You are already authorized",
    "crud.field.destinationAirPort.title": "Airport of destination",
    "form.order.internalSystemOrderCode": "Cargo Reference",
    "detailsOfCalculation.calcIDNew": "Quotation №",
    "detailsOfCalculation.fields.conversionPercent2": "conversion",
    "operatorAdmin.partners": "Partners",
    "detailsOfCalculation.fields.conversionPercent1": "Currency",
    "indicator.deliveryToRecipientDate": "Delivery To Recipient",
    "common.documents.fileName": "File Name",
    "crud.field.id.title": "№",
    "ordersList.now": "Now:",
    "form.field.includeInInsurance": "Add insurance",
    "form.wizard.title.insurance": "Cargo insurance",
    "reports.barChart.SEA_LINE": "Shipping lines",
    "crudForm.field.railwayOperator": "Railway operator",
    "form.field.address.from.placeholder": "From (address or zip) ...",
    "crud.field.organization.phone.title": "Company Phone",
    "LogisticDocType.PACKING_LIST": "Packing list",
    "deliveryOrders.requestId": "Sub-order number",
    "trunking.form.uploadTitle": "Tariff uploading",
    "operatorAdmin.directories.templateDescriptionTariff": "Description for the tariff",
    "detailsOfCalculation.customs.desc": "Description",
    "crud.field.active.no": "Invalid",
    "deliveryOrders.orderList.status.notDelivered": "Not Delivered",
    "CMR": "CMR:",
    "common.download": "Download",
    "constants.tarifications.absolute": "Absolute",
    "HOME_RAILWAY": "House Railway Bill:",
    "detailsOfCalculation.pieces": "pieces",
    "detailsOfCalculation.orderInfo.submit": "Submit",
    "crud.field.shippingDate.title": "Cargo readiness date (if known)",
    "form.select.loading": "Loading...",
    "customsOfCargo.fields.weight": "Weight",
    "wizard.cargo.customsOfCargo": "Place of customs clearance",
    "confirm.order.fail": "Error, please ensure that all fields are filled in correctly",
    "orderForm.commentPrice": "Rate/Comments:",
    "detailsOfCalculation.filters.currency": "Currency",
    "form.field.commission.integrator.percent.min": "not less than",
    "subscription.manage.tariffs.section": "Manage your subscription",
    "crud.field.departurePort.title": "Port of origin",
    "constants.typeOfRoadGeneral.wm": "for w/m",
    "crudForm.dictionary.btn.removeHatch": "Delete Compartment",
    "form.order.shippingDateFact": "Cargo readiness date, actual",
    "order.createNewManual.detailsOrder.changeOwnerOrg.title": "Company of the owner",
    "agreement.modal.title": "Terms of use",
    "detailsOfCalculation.callManager.modalSendBtn": "Send",
    "trunking.form.counterSecondString": "out of",
    "subscription.main.dateCreated.text": "Date of subscription:",
    "constants.placeTypes.transportHub": "Transport hub",
    "indicator.customsClearance.arrival.fact": "Customs Clearance, arrival (fact)",
    "crud.field.comment.title": "Comment",
    "service.serverError": "Server error",
    "crud.field.organizationName.title": "Company name",
    "deliveryOrders.delivery": "Delivery",
    "detailsOfCalculation.insurance.cargoCost": "Cargo value",
    "crudForm.field.surcharges": "Surcharges",
    "crud.field.error.organization.usersExists": "Uanble to delete a company who has opened delivery orders",
    "trunking.form.noLoadedFile": "No file uploaded",
    "detailsOfCalculation.customs.title": "Customs Clearance",
    "wizard.subscribe.cancelBtnText": "No, thanks",
    "partner.bankDetails.form.field.ogrn": "Primary State Registration Number",
    "constants.typeOfRoadGeneral.radius": "for diameter",
    "register.submit": "Register",
    "site.leftMenu.link.news": "News",
    "constants.placeTypes.seaPort": "Sea port",
    "crud.field.transportationType.title": "Transportation type",
    "crud.field.orderNumber": "Order number",
    "reports.statusTest.emptyAllReports": "Generate reports",
    "form.field.commissionPublic": "Public",
    "constants.quarter": "Quarter",
    "common.documents.orderId": "Order number",
    "orderForm.estTransitTime": "Transit Time/Estimated:",
    "mainMenu.subscriptions.userSubscription": "Clients subscriptions",
    "form.order.common": "Common",
    "crud.field.size.title": "Size (L, W, H)",
    "site.footer.copyright": "© {thisYear} <a href='https://cargobar.dev/' target='_blank'>CARGOBAR</a>. All rights reserved.",
    "ru.cargobar.imports.tariff.ContainerSeaFreightTariffImportServic.transitTime": "Invalid transit time. Row {0}, column {1}.",
    "crud.field.GENERAL_CARGO": "LCL",
    "modal.save.calculation.checkbox.title": "Save for the client",
    "deliveryOrders.cargoInformation.cargoWeightTitle": "Weight",
    "crud.field.freightCondition.title": "Freight terms",
    "detailsOfCalculation.documentDeadline": "Deadline for submission of documents to the shipping line",
    "detailsOfCalculation.outdatedCalculationMsg": "The present quotation needs to be updated.",
    "crud.field.direction.title": "Direction",
    "deliveryOrders.cargoInformation.title": "Cargo Information",
    "constants.month.august": "August",
    "modal.orderInfo.owner": "Owner",
    "subscription.switchToFree.btn.yes.text": "Confirm",
    "form.field.position": "Position",
    "common.status.current": "Current stage",
    "operatorAdmin.directories.freightSurcharges": "Freight surcharges",
    "tracking.fields.organization": "Organization",
    "form.field.commission.customs_road_transportation.menu": "Bonded road carrier",
    "partner.rates.railwayTransportation": "Railway transportation",
    "orderForm.sidesLimit": "Maximum number of notified parties reached",
    "crud.field.position.title": "Position",
    "indicator.pointOfPickup.load": "Point Of Pickup, load",
    "operatorAdmin.directories.airportsHubs": "Airports",
    "orderForm.notificateConsignee": "Notify the consignee",
    "common.documents.userName": "Creator of the document",
    "subscription.main.tariff.text": "Subscription:",
    "customsOfCargo.fields.customsPrice": "Customs fee",
    "commentDescription.button.create": "Add new",
    "user.invalidOperatorOrganization": "Invalid company for a user with an operator's authorisation",
    "indicator.pointOfArrival.arrival.fact": "Point Of Arrival, arrival (fact)",
    "wizard.find": "Find",
    "constants.nds.currentRate": "20",
    "form.field.position.placeholder": "Enter your position",
    "deliveryOrders.orderBlock.orderActions.getNotifications": "Subscribe",
    "crud.field.generalCargoRangeRate.price": "price",
    "orderForm.actPrice": "Rate/Actual:",
    "site.leftMenu.link.profile": "Profile",
    "deliveryOrders.status.CONFIRMED": "Confirmed",
    "detailsOfCalculation.filters.sort": "Sort",
    "form.field.users": "Users",
    "order.location.seaPort.required": "This is a required field",
    "login.submit": "Login",
    "detailsOfCalculation.services_departure": "Departure port charges",
    "wizard.tooltip.use20Container": "We recommend considering FCL shipment for the size of your cargo.",
    "crudForm.field.TypeOfTariff": "Type",
    "operatorAdmin.transportationRules": "Transportation rules",
    "detailsOfCalculation.transitTimeNew": "Estimated transit time",
    "dialog.confirm.default.question": "Are you sure?",
    "crudForm.field.description": "Description",
    "tracking.fields.statuses": "Status",
    "crudForm.button.apply": "Apply",
    "indicator.pointOfPickup.ready": "Point Of Pickup, ready",
    "ratingReport.scale": "Scale",
    "constants.NOT_PAID": "Not paid",
    "deliveryOrders.listItem.weight": "Weight",
    "deliveryOrders.cargoInformation.cargoVolumeTitle": "Volume",
    "partner.rates.trunking": "Trucking charges",
    "crudForm.dictionary.field.size": "Size,(L, W, H)",
    "crudForm.field.transportationType": "Transportation type",
    "wizard.cargo.form.car.title": "Trucks",
    "crud.field.departureAirPort.title": "Airport of origin",
    "rating.page.title": "User rating",
    "reports.field.month": "Month",
    "deliveryOrders.cargoTracking.table.service": "Service",
    "crud.filter.include": "Include",
    "constants.typeOfRoadGeneral.rc": "regional coefficient",
    "partner.rates.railwayServices": "Railway charges",
    "form.field.confirmCode": "Confirmation code",
    "common.delete.success": "Data deleted",
    "crud.label.yes.no": "Yes/No",
    "orderForm.departureDate": "Departure Date:",
    "reports.barChart.ROAD_TRANSPORT_TARIFF": "Road Transportation Tariffs",
    "indicator.pointOfPickup.ready.plan": "Point Of Pickup, ready (plan)",
    "common.applyTemplate": "Fill from template",
    "crud.field.franchise.title": "Franchise",
    "person.delivery": "Bookings",
    "LogisticDocType.FREIGHT_INVOICE": "Freight invoice",
    "deliveryOrders.orderList.headerTable.cargo": "Cargo",
    "crudForm.field.airTransportation.to": "To",
    "crudForm.field.pricePerKg": "Price per kilogram",
    "form.order.incoterms": "Incoterms",
    "operatorAdmin.directories.airlineDisclaimers": "Air Freight terms and conditions",
    "deliveryOrders.orderList.headerTable.orderNumber": "Order Number",
    "login.title": "Authorization",
    "detailsOfCalculation.fields.vatPersent": "VAT %",
    "crud.field.error.service.tariffExists": "The service can't be deleted as it  contains tariffs",
    "circular.parent.become.child": "You can't use a parent element as a subsidiary one",
    "detailsOfCalculation.RenewBtn": "Update",
    "dialog.confirm.commission.notSaveData.question": "Are you sure you want to exit before saving the data?",
    "operatorAdmin.directories.trucks": "Trucks",
    "wizard.customsOfCargo.containerImP": "container",
    "crud.filter.hideExpired": "Hide expired",
    "common.alertWorkJobScan": "At the moment, the statistics of popular destinations is being analyzed. The results will be available after the analysis is completed.",
    "detailsOfCalculation.fields.includeService": "Include this service",
    "form.field.station": "Select railway station",
    "common.unsupported.geo.region": "You are not authorized to enter rates for this trade lane",
    "auth.banned.message": "Your account has been temporarily suspended due to several failed login attempts. Please try again after 5 minutes.",
    "crud.uploadFromExcel.tooltip": "All tariffs should be uploaded in our standard form. Please use the template from this page.",
    "crud.add.newOrder": "New inquiry",
    "profile.organizationTemplates": "Organization templates",
    "form.field.periodEnd": "Period (until)",
    "detailsOfCalculation.to": "to",
    "crudForm.field.billRate": "Quote per B/L",
    "customsOfCargo.rate.specific": "Customs duty is charged at a rate of {value} {currency} of the 1 {unit}. VAT {nds}%",
    "form.field.commission.integrator.percent.max": "not more than",
    "person.button.edit": "Edit",
    "crud.field.address.title": "Address",
    "subscription.main.datePayNext.text": "Date of the upcoming payment:",
    "form.field.commissionPrivate": "Private",
    "deliveryOrders.cargoTracking.table.cost": "Cost",
    "form.order.cargoName": "Cargo name",
    "crud.field.error.railway.roadTariffExists": "Unable to delete a railway station as road transportation tariffs for it have been uploaded in the system",
    "operatorAdmin.directories.currencies": "Currencies",
    "radius.prices": "Rates",
    "crud.field.error.seaPort.portTariffExists": "Unable to delete a port as the system includes its port tariffs",
    "crud.field.error.consolidator.freightTariffExists": "A coloader can't  be deleted as their freight rates are in the system",
    "person.button.save": "Save",
    "form.order.bill": "Bill of Lading Details",
    "ru.cargobar.imports.tariff.ContainerSeaFreightTariffImportServic.port.from.not.found": "Such departure port is not available in the system. Row {0}, column {1}.",
    "reports.lineChart.countOfClient": "Number of Clients",
    "deliveryOrders.status.PLANNING": "Planning",
    "partner.generalInformation.form.field.factAddress.placeholder": "Enter mailing address",
    "crud.remove.request.label": "Are you sure you want to cancel your application for subscription?",
    "crudForm.field.costForWM": "Cost per w/m",
    "confirm.help.statusNotConfirm": "Dear user! We noticed that we have already sent a registration confirmation code to your email. Please check your mail, including the spam folder. We have sent a re-verification code to complete the registration.",
    "page.main": "Main",
    "form.order.companyName": "Company name",
    "customsOfCargo.rate.max_of_both": "Customs duty is charged at a rate of {mainRate}% of the customs valuation, but not less then {value} {currency}/{unit}. VAT {nds}%",
    "crud.field.surchargeText": "surcharge",
    "partner.userInfo.form.lastName": "Last Name",
    "detailsOfCalculation.fields.showTariffDetailsNew": "Details",
    "crud.field.cargoTypeName.title": "Cargo type",
    "operatorAdmin.directories.railwayHubs": "Railway stations",
    "form.order.companyAddress": "Company address",
    "wizard.cargo.unit": "Units",
    "partner.rates.public": "Download from Excel",
    "wizard.directionForm.addressFrom": "Pick-up point",
    "logisticBlocks.inExcel": "Excel report",
    "detailsOfCalculation.tryEnd": ".",
    "crudForm.field.perKm": "Per km",
    "wizard.cargo.form.amount.placeholder": "Enter number",
    "notifications.read": "Mark as read",
    "subscription.tariff.create.request.confirm": "Your application for the subscription has been approved.",
    "crud.field.usageLimit.crud.title": "Number of quotations used",
    "organizationsProfiles.deleteProfileConfirm": "Are you sure you want to delete profile from company?",
    "crud.field.error.railwayOperator.railwayStationTariffExists": "Railway operator can't be deleted as road transportation tariffs for them are in the system",
    "detailsOfCalculation.insurance.interestType": "Type of interest",
    "crud.save.success": "Element was saved",
    "crudForm.field.transitCargo.customCleared": "Customs clearance completed",
    "form.order.date": "Date",
    "excel.tariff.no.directionTariffType": "No direction FROM_CITY or TO_CITY",
    "crud.field.arrivalPlace.button.add": "Add consignee details",
    "wizard.cargo.form.weightTo": "To",
    "wizard.cargo.form.vehicleBody": "Vehicle body",
    "crud.field.country.name.title": "Country",
    "trunking.form.defaultFileName": "Trucking charges tariff",
    "common.documents.downloadActionTitle": "Download",
    "page.expiredTariffs.railway-services": "Railway charges",
    "common.errorNullTemplate": "Template cannot be empty! Fill in the details of the shipper and/or consignee.",
    "form.field.commission.container.to": "to",
    "crudForm.field.airTransportation.from": "From",
    "reports.types.POPULAR_DESTINATIONS_TREND": "Popular destinations (in relation to the previous period)",
    "deliveryOrders.cargoTracking.table.total": "Total",
    "crud.pagination.title": "Total",
    "deliveryOrders.cargoTracking.table.taxName": "VAT",
    "crud.field.auditEventType.title": "Audit event type",
    "confirm.help.status": "We have sent a confirmation code to your email. Please check all folders, including spam.",
    "crud.field.paid.title": "Paid API",
    "crud.field.createDate.title": "Created At",
    "register.partner.description": "Description of the platform for a partner",
    "organizationsProfiles.rejectProfileConfirm": "Are you sure you want to reject the profile?",
    "partner.bankDetails.form.field.inn": "TIN",
    "common.unique": "This value must be unique",
    "trunking.form.addFile": "Attach file",
    "LogisticDocType.HOME_BILL": "House Bill of lading/air waybill",
    "constants.vehicleDistanceRange.UNDER_100": "<100 km",
    "ru.cargobar.imports.tariff.ContainerSeaFreightTariffImportServic.freightTerms.empty": "Freight terms is empty. Row {0}, column {1}.",
    "crudForm.field.service": "Service",
    "detailsOfCalculation.callManager.checkTransitBtn": "Send request",
    "crud.filter.title": "Filter",
    "wizard.cargo.form.size.height": "Height",
    "importTranslates.resultCountAll": "Total imported:",
    "crud.filter.from": "From",
    "modal.orderInfo.status": "Status",
    "crud.field.additions.title": "Surcharges",
    "reports.types.TARIFF_COUNT": "Number of valid tariffs",
    "wizard.subscribe.message.mobile": "Subscribe to biweekly newsletters from Agorafreight.com to stay tuned. No spam ever – just up-to-date news.",
    "trunking.form.uploadError": "Upload error",
    "crud.filter.cargoContainerType": "Container type",
    "customsOfCargo.fields.price.placeholder": "Value",
    "wizard.cargo.form.weight": "Weight",
    "widget.order.thanks": "Thank you for placing a booking with us!",
    "detailsOfCalculation.filters.updated.text": "Data was successfully updated",
    "crudForm.field.consolidation": "Consolidation",
    "detailsOfCalculation.fields.vat": "VAT",
    "crud.field.regionId.title": "Country",
    "crudForm.bulkOperations.nameCode.prolong": "Extend",
    "form.field.airport": "Select airport",
    "partner.services.serviceTypes.serviceProfiles": "Profiles of services",
    "form.order.zipCode": "ZIP Code",
    "operatorAdmin.users": "Users",
    "indicator.receiptOfDocuments.fact": "Point Of Arrival, receipt of documents (fact)",
    "detailsOfCalculation.customs.price": "Cost",
    "site.leftMenu.link.termsOfUse": "Terms of Use",
    "form.order.personName": "Name",
    "detailsOfCalculation.changeParams": "change parameters",
    "form.wizard.title": "Get a quote online",
    "crud.field.railwayOperator.title": "Railway operator",
    "deliveryOrders.orderList.searchBtnTitle": "Search",
    "crudForm.field.containerType": "Container type",
    "form.button.back": "Previous",
    "crud.field.language.title": "User language",
    "partner.bankDetails.form.field.numberAccount.title": "Bank account details",
    "reportResource.report.avg.countInternalVariants": "Average number of calculation options excluding external partners",
    "crud.field.error.seaLine.surchargeExists": "A shipping line can't be deleted as sea freight tariff has been entered for it",
    "crud.field.RELATIVE": "Relative",
    "partner.userInfo.form.phone.placeholder": "Enter phone number",
    "crud.filter.show": "Show",
    "crud.field.error.seaPort.roadTariffExists": "Unable to delete a hub as the system includes its road haulage tariffs",
    "constants.month.march": "March",
    "subscription.request.title": "Application track record",
    "crudForm.bulkOperations.form.field.name": "Person Name",
    "wizard.tooltip.selectContainerOther": "Please view container options.",
    "crudForm.field.betweenTheCityPerDistance": "Between cities (per km)",
    "notifications.delete": "Delete notification",
    "crud.field.locked.title": "Locked",
    "crudForm.field.documentDeadline.placeholder": "Deadline for submission of documents",
    "crud.field.city.name.title": "City",
    "crud.field.countVariantsInternalCalculation.title": "Number of calculation options excluding external partners",
    "crud.field.cargoKind.title": "Container Kind",
    "crud.field.region.title": "Region",
    "deliveryOrders.listItem.size": "Size",
    "form.field.limit": "Limit",
    "form.order.arrival": "Place of Origin",
    "crud.edit.bulkOperation": "Edit selection",
    "crudForm.field.demurrage": "Demurrage",
    "crud.field.error.profile.cantRemove": "Unable to remove a profile with a company attached to it",
    "register.client.description": "Description of the platform for a client",
    "common.send": "Send",
    "ordersList.left": "Left:",
    "crud.field.seaLine.title": "Shipping line",
    "crudForm.field.typeOfCargo": "Type of cargo",
    "wizard.customsOfCargo.title": "Customs clearance",
    "disclaimers.calculation.label": "Additional terms",
    "operatorAdmin.directories.events": "Events",
    "reports.field.count": "Number",
    "excel.tariff.no.organization": "Organization not found",
    "operatorAdmin.emptyOrders": "Empty quotes",
    "crud.field.countryFrom.title": "From",
    "crud.field.routeType.title": "Route type",
    "error.bik.size.9": "Enter the correct BIK (9 digits)",
    "crudForm.field.view": "View",
    "orderForm.cargoVolume": "Total volume:",
    "detailsOfCalculation.fields.tariff": "Tariff",
    "operatorAdmin.listOfAPIs.disconnect": "Disconnect",
    "crud.field.cargoTypeCalculation.title": "Number of quotations (FCL/LCL/FTL)",
    "wizard.cargo.form.size.length.placeholder": "L",
    "form.order.destination": "Place of Destination",
    "crudForm.field.common.documents.nameOfDocument": "Document's name",
    "detailsOfCalculation.fields.seaLine": "Shipping line",
    "operatorAdmin.directories.template": "Templates for orders",
    "partner.bankDetails.form.field.inn.placeholder": "Enter TIN  number",
    "OF_DESTINATION": "оf destination",
    "common.documents.numberOfDocument": "Document's number",
    "partner.salesRules": "Gross margin adjuster",
    "crud.field.error.railwayOperator.roadTransportTariffExists": "Railway operator can't be deleted as railway transportation tariffs for them are in the system",
    "crud.field.toHub.title": "To",
    "crud.field.error.profile.categoryExists": "Unable to remove a profile with existing categories",
    "crudForm.field.minWeight": "Min. weight",
    "crudForm.field.transitCargo.inTransit": "In transit",
    "detailsOfCalculation.hideDetails": "Hide details",
    "form.field.email": "Email",
    "mainMenu.mySubscription": "My subscription",
    "detailsOfCalculation.orderTable.volume": "Volume",
    "wizard.directionForm.address": "Address",
    "report.name": "Calculation №",
    "constants.typeOfCargoFull.vehicle": "Vehicle",
    "crudForm.field.country": "Country",
    "crudForm.dictionary.field.parent": "Main organization",
    "orderForm.warning.consigneeInfoMessage": "Consignee details",
    "crud.field.totalCost.title": "Total cost",
    "wizard.insuranceForm.price": "Rate",
    "trunking.form.deleteAll": "Delete all",
    "form.field.password": "Password",
    "subscription.page.config.resetFree.value.MONTH": "Month",
    "orderForm.to": "Destination:",
    "crudForm.field.PORT_ADR": "Port ⇄ Address",
    "crud.field.nds.title": "VAT",
    "crud.field.errors.title": "\"Errors\"",
    "form.field.fullName": "Full Name",
    "contacts.confirm.message": "If you delete a notify party the data can't be restored",
    "reports.lineChart.countOfFreight": "Sea Freight",
    "crud.field.aviaCompany.title": "Airline",
    "constants.charging.PORT_ADR": "Port ⇄ Address",
    "documents.file.download.success": "Document downloaded successfully",
    "crudForm.field.RequiredServices": "Required Service",
    "modal.orderInfo": "Information about order",
    "detailsOfCalculation.calculationsIsEmpty.bot": "Please send your inquiry in one click and if there is a tariff we will email you a quotation.",
    "reports.field.btn.restart": "Regenerate",
    "error.ogrn.size.13": "Enter the correct Primary State Registration Number (13 digits)",
    "customsOfCargo.form.removeArticles": "Remove goods",
    "deliveryOrders.cargoTracking.table.quantity": "Quantity",
    "detailsOfCalculation.railway_station_services": "Station charges",
    "constants.customHouseAir.inPort": "Customs clearance at the airport of unloading",
    "crud.field.hub.title": "Hub",
    "reports.field.btn.search": "Display",
    "detailsOfCalculation.fields.cargo": "Cargo",
    "crud.field.reportDetail.title": "Detailed report",
    "common.documents.nameOfDocument": "Document's name",
    "form.field.vehicle": "Vehicle",
    "reports.lineChart.countOfAllOrganization": "Sea freight + Road transportation",
    "templates.documents.table.headers.default": "Default Template",
    "BOL_WITHOUT_SYMBOL": "Bill of Lading",
    "reports.types.POPULAR_DESTINATIONS_COUNTRY": "Popular destinations (by countries)",
    "detailsOfCalculation.airport_service": "Airport services",
    "role.user.adminOrganization": "Organization Administrator",
    "detailsOfCalculation.filters.cancel": "Reset",
    "partner.generalInformation.form.field.email": "Official email",
    "detailsOfCalculation.fields.container": "Container type",
    "crudForm.dictionary.field.name": "Name",
    "reports.lineChart.months": "Months",
    "crud.field.modifier.title": "Type",
    "AWB_WITHOUT_SYMBOL": "AWB",
    "form.field.organization.name": "Name",
    "form.wizard.seo.title": "Online freight rates calculator | Digital Freight Forwarder Agora Freight",
    "articles.more.than.cargo": "Gross weights used for customs costs calculation and cost of  transportation should match each other",
    "operatorAdmin.directories.forwarders": "Forwarders",
    "site.leftMenu.link.forwarders": "Forwarders",
    "importTranslates.title": "Import translations of hubs/geo objects",
    "modal.contactUs.failBody": "Couldn't send the message. Please try again later.",
    "reports.alert.waitRestart": "Action completed successfully. It is necessary to wait a minute until new graphics are generated",
    "detailsOfCalculation.callManager.modalSuccessBody": "Your request will be dealt with promptly and quotation will be sent to your email address.",
    "crud.field.active.yes": "Valid",
    "crud.disclaimers.railway.conditionText": "Disclaimer",
    "crud.field.vehicleRates.distanceRange.title": "Distance range",
    "detailsOfCalculation.fields.containers": "Containers",
    "crud.field.active.title": "Active",
    "requestStatus.switch": "Change of subscription plan",
    "crud.field.profile.title": "Profile",
    "detailsOfCalculation.createDate": "Date of rate indication",
    "form.order.customsCode": "HS code",
    "indicator.deliveryToRecipientDate.plan": "Delivery To Recipient (plan)",
    "operatorAdmin.directories.transportHubs": "Transport hubs",
    "crudForm.bulkOperations.field.selectedItems": "Selected items",
    "common.contactsTitle": "Contacts",
    "crud.field.forwarderTemplate.title": "Template type",
    "constants.insuranceTransportType.land": "Land",
    "link.back.text": "< Back",
    "reportResource.report.avg.timeExecution": "Average quotation execution time (seconds)",
    "report.transportation.road": "road transportation",
    "crud.field.nds.include": "include",
    "wizard.cargo.totalVolume": "Total volume",
    "crud.field.countriesRoute.title": "Route type",
    "crudForm.dictionary.field.nameTemplate": "Template name",
    "report.transportation.multimodal.rail": "multimodal transportation (rail + road)",
    "form.field.commission.addStr.common": "Range",
    "form.field.commission.consolidator.menu": "Coloader",
    "crud.field.createDateTime.title": "Date of creation",
    "confirm.order.reset": "Your order was successfully cleared",
    "crudForm.dictionary.field.code.placeholder": "Enter code",
    "userRegistration.passwordError": "The password must contain at least eight characters, including at least one number and both lower and uppercase letters.",
    "crud.field.periodStart.title": "Period (from)",
    "crud.field.conditionText.title": "Disclaimer",
    "indicator.invoiceDate": "Invoice date",
    "crudForm.field.notRequired": "Optional",
    "deliveryOrders.orderList.status.onTheWay": "On The Way ",
    "crudForm.field.transitCargo": "Transit",
    "crudForm.field.forWM": "per w/m",
    "form.field.confirmPassword": "Password (confirm)",
    "detailsOfCalculation.fields.general": "Less-than-container/truckload cargo",
    "crudForm.dictionary.field.organizationName": "Organization name",
    "ordersList.plan": "* plan",
    "AIRPORT_HUB": "Airport",
    "crud.field.legalAddress.title": "Legal Address",
    "orderCalculation.hidePriceLabelHover": "Log in or register to see the quotation",
    "form.field.firstName.placeholder": "Enter first name",
    "crudForm.field.weightUnit": "Unit of weight measurement ",
    "reportResource.downloadReportExcelBtn": "Download report (Excel)",
    "common.settingsBtn": "Settings",
    "crud.field.objectType.title": "Object type",
    "partner.rates.cargo": "Cargo",
    "orderForm.po": "Cargo reference:",
    "site.footer.developedBy": "Developed by",
    "constants.typeOfRoadGeneral.ldm": "for LDM",
    "person.generalInformation": "General information",
    "partner.generalInformation.form.field.factAddress": "Mailing address",
    "crud.field.chargingName.title": "Billing type",
    "crudForm.dictionary.field.rates_currency": "Rate to other currencies:",
    "crudForm.dictionary.field.insuranceRate": "Insurance rate",
    "wizard.insuranceForm.addRange": "Add",
    "constants.month.december": "December",
    "form.field.postcode": "Postcode",
    "report.transportation.vehicle": "trucking",
    "crud.field.companyName.title": "Company name",
    "deliveryOrders.status.AT_DESTINATION": "At the destination",
    "common.someRequired": "Must be filled for at least one type of container",
    "modal.changePassword.oldPassword.placeholder": "Enter old password",
    "partner.rates.public.crud": "Tariffs",
    "crudForm.bulkOperations.nameCode.copyLink": "Copy link",
    "constants.charging.distance": "Distance",
    "deliveryOrders.orderBlock.orderActions.details": "Details",
    "deliveryOrders.cargoInformation.cargoAmountTitle": "Quantity",
    "crud.field.error.geoObject.stationExists": "Unable to delete a geo object since airports are entered for it in the system",
    "detailsOfCalculation.orderTable.vehicle.type": "Type",
    "detailsOfCalculation.fields.billing": "Billing",
    "subscription.main.usage.text": "Number of quotations used:",
    "wizard.directionForm.airport": "Airport",
    "wizard.cargo.form.size.M": "m",
    "operatorAdmin.insuranceRates": "Insurance rates",
    "orderForm.of": "of",
    "crud.field.fromLocation.title": "From",
    "templates.documents.edit.form.nofile": "File not selected",
    "reports.types.POPULAR_DESTINATIONS": "Popular destinations",
    "partner.bankDetails.form.field.fullName.placeholder": "Enter the name of legal entity",
    "person.button.changePassword": "Change password",
    "crudForm.field.maxSize": "Maximum size of colli",
    "crud.disclaimers.railway.railwayStationName": "Railway station",
    "crud.field.seaPort.title": "Port",
    "operatorAdmin.title": "Admin dashboard",
    "crud.field.departureDataNotFill": "Shipper data is not filled",
    "crud.filter.deleteFilterConfirm": "Are you sure you want to delete a filter?",
    "subscription.main.text": "General information:",
    "page.expiredTariffs.road-transportation": "Origin trucking rates (full and part loads)",
    "error.corrAccount.size.20": "Enter the correct corresponding account number (20 digits)",
    "crud.field.profileId.title": "Profile",
    "crudForm.field.intraportForwarding.auto.placeholder": "Entered automatically",
    "templates.documents.table.headers.name": "Name",
    "common.documents.addDocumentOrder": "New document entry",
    "constants.franchiseTypes.fixed": "Fixed",
    "wizard.subscribe.message.second": "No spam ever – just up-to-date news.",
    "constants.charging.rate": "Rate",
    "report.cargo.hourly": "LCL/LTL",
    "crud.field.error.surcharge.cantremove": "unable to remove a surcharge with values entered",
    "crud.field.confirmationCode.title": "Confirmation Code",
    "organizationsProfiles.status.NEW": "New",
    "deliveryOrders.status.ARRIVED_TO_DEPARTURE_HUB": "Arrived to departure HUB",
    "crudForm.field.freightConditions": "Freight terms",
    "crud.field.seaLineId.title": "Shipping line",
    "constants.month.april": "April",
    "orderForm.modal.driverPhone": "Driver's mobile phone",
    "crudForm.field.airTransportation.price": "Price",
    "wizard.tooltip.useOtherContainer": "We recommend considering FCL shipment for the size of your cargo.",
    "detailsOfCalculation.toDoor": "door delivery",
    "detailsOfCalculation.priceOverTime": "The price indicated is the minimum for ({minHour} {hour}), thereafter the hourly payment is applied ({priceForHour} in hour)",
    "crudForm.dictionary.field.organizationId.placeholder": "Company ID",
    "crudForm.field.airTransportation.auto.placeholder": "Enter automatically",
    "crud.form.add.title": "Rate entry",
    "wizard.cargo.form.vehicleType": "Vehicle type",
    "documents.replace.doc": "This document has already been uploaded. Replace with a new one?",
    "widget.order": "Order",
    "deliveryOrders.ownerInfo": "Owner",
    "partner.rates.complexTariff": "Intermodal rates",
    "detailsOfCalculation.details": "Show details",
    "logistBlock.plan": "Plan",
    "modal.changePassword.passwordConfirmation": "Repeat new password",
    "detailsOfCalculation.insurance.title": "Cargo insurance",
    "crudForm.field.betweenTheCity": "Between cities",
    "common.equalsroadTransit": "The transit hub must not be the same as the origin or destination hub",
    "crudForm.dictionary.tab.tabHatch": "Compartment",
    "form.button.next": "Next",
    "crud.field.error.aviaCompany.airTariffExists": "Unable to delete an airport since road transportation tariffs have been uploaded for it",
    "form.wizard.allTerminals": "All terminals",
    "partner.userInfo.form.firstName.placeholder": "Enter first Name",
    "modal.contactUs.title": "Contact Us",
    "detailsOfCalculation.fields.railwayOperator": "Railway operator",
    "person.title": "Customer information",
    "constants.commissionTypes.percent": "Percent from sales",
    "crudForm.dictionary.languages.title": "Languages",
    "wizard.cargo.form.typeOfCargo": "Cargo type",
    "reports.barChart.SEA_PORT": "Ports",
    "form.field.price": "Amount",
    "partner.geography.geoTypes.country": "Country",
    "deliveryOrders.order": "Order",
    "subscription.page.config.resetFree.value.WEEK": "Week",
    "logistBlock.documentsDate": "Documents receipt",
    "crud.field.sellerRewardPercent.title": "Seller Reward Percent",
    "wizard.directionForm.addressTo": "Delivery address",
    "detailsOfCalculation.fields.conversionPercent": "Currency conversion",
    "operatorAdmin.directories.railwayDisclaimers": "Railway Transportation terms and conditions",
    "form.wizard.title.common": "Other",
    "indicator.pointOfArrival.departure.fact": "Point Of Arrival, departure (fact)",
    "customsOfCargo.form.title": "Customs payments calculation",
    "deliveryOrders.orderList.excelReport": "Excel report",
    "excel.tariff.no.identify.direction": "Failed to locate a major city",
    "modal.orderInfo.fromLocation": "From location",
    "partner.generalInformation.dictionaries.title": "Company Profile",
    "orderForm.cargoDescription": "Cargo description:",
    "site.footer.menu.agreement": "Terms of Use",
    "orderCalculation.hidePriceLabelModal": "To see the quotation you need to be logged in / registered",
    "templates.documents.btn.downloadTestWord": "View in WORD",
    "auth.fail.message": "Incorrect login or password",
    "crud.field.ON_CARGO": "For cargo",
    "modal.orderInfo.resultLink": "View report",
    "OF_PICKUP": "оf pick-up",
    "form.field.cityAndAddress": "City, address",
    "button.logout": "Logout",
    "orderForm.editSide": "Edit",
    "crudForm.field.airTransportation.placeholder": "Enter data",
    "page.expiredTariffs.air-transportation": "Air transportation",
    "indicator.customsClearance.departure.fact": "Customs Clearance, departure (fact)",
    "partner.generalInformation.form.field.email.placeholder": "Enter official email",
    "crud.field.resetedUsageLimit.title": "Reset successful",
    "modal.contactUs.email": "Email",
    "crud.field.error.geoObject.portExists": "Unable to delete a geo object since ports are entered for it in the system",
    "operatorAdmin.directories.airlines": "Airlines",
    "crud.field.type": "Type of service",
    "partner.generalInformation.form.field.name.placeholder": "Enter name",
    "form.field.phone": "Phone Number",
    "mainMenu.subscriptions.conf": "Configuration",
    "detailsOfCalculation.register": "register",
    "deliveryOrders.listItem.status": "Status",
    "subscriptionTariffs.title": "Subscriptions",
    "common.changeSelfOrganization": "You cannot change the organization on your own",
    "crudForm.bulkOperations.form.field.email": "Email address",
    "error.numberAccount": "Enter the correct account number (20 digits)",
    "detailsOfCalculation.fakePartPaths": "Quotation for this part should be clarified",
    "BOL": "Bill of Lading:",
    "wizard.cargo.form.insurance.interest": "Type of merchandise",
    "crud.field.externalAPI.title": "External API",
    "indicator.pointOfDestination.arrival": "Point Of Destination, arrival",
    "partner.userInfo.form.middleName.placeholder": "Enter middle Name",
    "site.footer.subscribe.title": "Please register here to receive updated news on Agora Freight development",
    "indicator.pointOfArrival.arrival": "Point Of Arrival, arrival",
    "reports.barChart.COUNTRY": "Countries",
    "detailsOfCalculation.ContainerType.Label": "Container type",
    "orderForm.consignee": "Consignee:",
    "page.notFound.tittle": "Page not found",
    "form.wizard.delivery": "Delivery address",
    "crud.field.newVersion.title": "New value",
    "deliveryOrders.orderInfo.title": "Order Information",
    "deliveryOrders.status.STANDBY": "Standby",
    "common.documents.order.title": "List of all documents for a booking",
    "modal.contactUs.name": "Name",
    "register.welcome": "Registration",
    "crud.field.nds.notInclude": "not included",
    "partner.rates.plato": "Russian road tax",
    "operatorAdmin.directories.languages": "Languages",
    "form.field.email.placeholder": "Enter email",
    "constants.charging.betweenCities": "Between cities",
    "detailsOfCalculation.filters.error.text": "An error occurred, please change the search parameters",
    "wizard.direction.title": "Make a Calculation",
    "customsOfCargo.fields.quantity": "Amount",
    "crudForm.field.franchiseType": "Franchise type",
    "crudForm.field.airBillRate": "Price per AWB",
    "circular.dependence": "You can't link an element to itself",
    "user.sentRestorePassword": "Password recovery instructions was sent on your email",
    "orderForm.numberOfFirstContainer": "Number of the first container in the B/L:",
    "detailsOfCalculation.tryTo": "Try to",
    "form.field.exporter": "Exporter",
    "crud.filter.showExpired": "Show expired",
    "crudForm.field.file": "File",
    "partner.generalInformation.form.field.phone.placeholder": "Enter phone",
    "templates.documents.edit.form.header.edit": "Edit template",
    "user.passwordDontMatch": "Entered passwords do not match",
    "indicator.pointOfPickup.load.plan": "Point Of Pickup, load (plan)",
    "crud.field.dateFrom.title": "Date from",
    "constants.routeType.localDeparture": "Local (departure)",
    "logistBlock.cargoTracking": "Cargo tracking",
    "partner.generalInformation.form.field.phone": "Phone",
    "detailsOfCalculation.orderTable.amount": "Quantity",
    "crud.edit.bulkOperation.element": "el.",
    "crud.label.yes": "Yes",
    "ru.cargobar.imports.tariff.ContainerSeaFreightTariffImportServic.fifo.container.40HC": "Invalid cost for a 40HC FIFO container. Row {0}, column {1}.",
    "operatorAdmin.organizations": "Companies",
    "crudForm.field.percent": "Percent",
    "form.field.commission.container.from": "Number of containers from",
    "form.order.internalId": "Cargo Reference",
    "confirm.regenerate": "Send a new code",
    "templates.documents.edit.form.cancel": "Cancel",
    "form.field.cost": "Cost",
    "crud.field.forwarderId.title": "Forwarder",
    "partner.geography.arrivalRegions": "Regions of arrival",
    "deliveryOrders.orderInfo.email": "E-mail",
    "crud.field.createOrderDate.title": "Date of calculation",
    "detailsOfCalculation.hourRP": "hours",
    "deliveryOrders.cancel": "Cancel",
    "crudForm.dictionary.field.coordinates.longitude.placeholder": "Longitude",
    "orderList.NumberColumnTitle": "№",
    "crudForm.field.period.on.6m": "Half a year",
    "crud.filter.exclude": "Exclude",
    "crudForm.dictionary.field.organizationProfiles": "Company profiles",
    "importTranslates.notSelect": "Not select",
    "deliveryOrders.orderParameters": "Parameters of an order",
    "crud.field.country.title": "Country",
    "partner.commission": "Forwarder's commission",
    "crud.field.cargoType.title": "Cargo type",
    "calculationUpdate.fail": "It is not possible to update the calculation using previously applied rates. We suggest you make a new calculation for this route.",
    "logistBlock.cargoReadyDate": "Cargo readiness date",
    "reports.types.POPULAR_DESTINATIONS_CARGO": "Popular destinations (by types cargo)",
    "reports.barChart.SEA_FREIGHT_TARIFF": "Sea Freight Tariffs",
    "RAILWAY_HUB": "Railway station",
    "crud.field.generalCargo.title": "Less-than-container/truckload cargo",
    "logistBlock.invoiceDate": "Invoice date",
    "crud.field.historyBillingList.crud.title": "Current subscription plan",
    "crudForm.field.forKg": "per kg",
    "LogisticDocType.INVOICE": "Invoice",
    "page.expiredTariffs.airport-services": "Airport charges",
    "orderForm.deleteSide": "Delete",
    "form.field.typeFrom": "Type of departure point",
    "constants.PAID": "Paid",
    "notifications.type.ExpiredCalculation.message": "Your saved quotation is out of date and is no longer valid.",
    "detailsOfCalculation.cargoIsOverSize.top": "The cargo dimensions indicated in your request do not fit into a regular trailer on this route.",
    "modal.changePassword.password": "Enter new password",
    "confirm.modal.title": "Confirmation code",
    "orderList.PriceColumnTitle": "Price",
    "constants.charging.hourly": "Per hour",
    "partner.services.serviceTypes.services": "Services",
    "crudForm.button.save": "Save",
    "wizard.field.containersNumber.placeholder": "Enter number of containers",
    "crud.field.container.title": "Container",
    "indicator.pointOfOrigin.departure.plan": "Point Of Origin, departure (plan)",
    "form.field.cost.placeholder": "Enter cost",
    "detailsOfCalculation.calculationInfo": "Rate includes:",
    "crudForm.field.AIR_ADR": "Airport ⇄ Address",
    "importTranslates.name.zh": "Name (ZH)",
    "crud.field.executionTime.second.title": "Processing time",
    "crud.field.phone.title": "Phone",
    "subscription.request.status.NEW": "New",
    "partner.generalInformation.form.field.legalAddress": "Legal address",
    "general.cargo.compound.containerBtn": "Full container load",
    "importTranslates.btn.wait": "Wait...",
    "crudForm.field.relativeRate": "Rate, (%)",
    "crudForm.field.transitHub": "Transit hub",
    "ratingReport.modal.title": "Detailed report on the user: ",
    "crud.field.error.geoObject.wrongLatitude": "Latitude should be within range [-90, 90]",
    "wizard.cargo.form.weight.placeholder.LB": "LB",
    "crud.field.latitude.title": "Latitude",
    "subscription.history.date": "Subscription  Date",
    "partner.rates.general": "LCL",
    "partner.generalInformation.form.title": "Company Information",
    "ratingReport.generateReport": "Generate a report",
    "wizard.customsClearance.noTariff": "Unable to calculate customs clearance costs for this lane",
    "detailsOfCalculation.orderTable.to": "To",
    "crud.field.numberAccount.title": "Invoice number",
    "crud.field.resetUsageLimitBtn.title": "Reset",
    "crudForm.field.period.on.3m": "Three months",
    "errorLimit": "We are sorry, there are no available quotations left in your subscription plan.",
    "crud.uploadFromExcel.example": "Tariffs Template",
    "crud.field.charging.title": "Billing type",
    "orderForm.orderName": "Booking",
    "crud.field.commentDescription.title": "Description",
    "confirm.already.done": "You have already confirmed your registration ...",
    "order.location.address.required": "This is a required field",
    "detailsOfCalculation.callTheManager": "Please сontact us",
    "wizard.cargo.form.weight.placeholder.KG": "KG",
    "form.wizard.title.to": "To",
    "detailsOfCalculation.integrator": "Integrator",
    "crudForm.bulkOperations.field.forItems": "Apply",
    "crudForm.field.port": "Port",
    "detailsOfCalculation.hourMn": "hours",
    "crud.field.toHub.name.title": "To hub",
    "crud.disclaimers.vehicle.conditionText": "Disclaimer",
    "page.expiredTariffs.sea": "Sea freight",
    "crudForm.field.period.on.2m": "Two months",
    "common.documents.file": "Attached file",
    "crud.field.transitHub": "Transit hub",
    "OF_ORIGIN": "оf origin",
    "crud.field.lastCalculationDateTime.title": "Date and time of the last calculation",
    "partner.rates.container": "FCL",
    "operatorAdmin.directories.chatbot": "Chatbots",
    "subscription.chooseTariff.btn": "Choose subscription",
    "crudForm.field.PORT_RAILWAY": "Port ⇄ Railway",
    "crud.disclaimers.vehicle.countryName": "Country",
    "partner.bankDetails.form.field.bik": "BIK",
    "orderForm.modal.cargoWeight": "Cargo weight:",
    "form.field.private.placeholder": "I herevy confirm the agreement",
    "common.roleIsUnknown": "-",
    "partner.rates.vehicle": "Vehicles",
    "deliveryOrders.status.CUSTOMS_CLEARANCE": "Customs clearance",
    "crudForm.field.airTransportation.transitTime": "Transit time",
    "ADDRESS": "City",
    "crudForm.field.period.on.1m": "One month",
    "constants.month.january": "January",
    "common.documents.creationDate": "Date of Creation",
    "templates.documents.edit.form.alert.exist": "A template has already been created for these parameters.",
    "crud.field.enabled.title": "Mode",
    "crudForm.field.ldmValue": "LDM value",
    "crudForm.value.placeholder": "Value",
    "crud.field.type.title": "Type",
    "form.field.bothSides": "Round trip",
    "crud.field.vehicleRates.title": "Vehicle",
    "customsOfCargo.remark": "The calculation is for indication purposes only",
    "crudForm.field.status.text": "Application status",
    "form.field.commission.road_transportation.menu": "Road carrier",
    "detailsOfCalculation.insurance.franchise": "Franchise",
    "tracking.fields.error.fact": "Fact date filled",
    "detailsOfCalculation.orderTable.vehicle.bodyType": "Trailer type",
    "constants.routeType.export": "Export",
    "common.copy.error": "Failed to copy data",
    "LogisticDocType.ANOTHER": "Other ",
    "operatorAdmin.directories.customsTerminals": "Customs terminals",
    "rates.error.accessDenied": "The organization is not authorised to create such tariff",
    "crud.field.fromHub.name.title": "From hub",
    "common.invalidZipCodes": "Invalid zip codes",
    "indicator.pointOfPickup.departure.fact": "Point Of Pickup, departure (fact)",
    "partner.generalInformation.form.field.profileStatus.NEW": "New",
    "deliveryOrders.status.IN_TRANSIT": "In transit",
    "page.expiredTariffs.railway-transportation": "Railway transportation",
    "clientErrorMsg.disclaimer.exists": "This desclamer already exists.",
    "crud.field.confirmed.title": "Confirmed",
    "form.field.locationType": "Location type",
    "crud.field.rates.title": "% of cargo value",
    "common.documents.error.id.none": "The specified booking number was not found",
    "partner.geography.geoTypes.regionOfWorld": "Continent",
    "crud.edit.downloadCSV": "Download all tariffs",
    "constants.month.june": "June",
    "report.unit.m": "m",
    "crud.field.station.title": "Railway station",
    "operatorAdmin.directories.airs": "Aircraft",
    "crud.field.transactionId.title": "Contract number",
    "crud.field.error.airport.airportTariffExists": "Unable to delete an airport since airport charges have been uploaded for it",
    "partner.rates.portServices": "Port charges",
    "constants.ordersSortFilterCurrency.cny": "CNY",
    "detailsOfCalculation.filters.save": "Apply",
    "crudForm.field.commissionIncluded": "Enabled",
    "crud.field.sellerRewardMin.title": "Seller Reward Min",
    "constants.surchargeTypes.onCargo": "For cargo",
    "crudForm.field.minTimeForTransport": "Min. time, (hour)",
    "orderForm.warning.notifyPartyMessage": "Add the notified party",
    "operatorAdmin.directories.geography": "Geography",
    "detailsOfCalculation.departureDate": "Departure date",
    "deliveryOrders.orderList.headerTable.internalId": "Cargo Reference",
    "constants.month.july": "July",
    "partner.geography.geoTypes.specialRegion": "Special region",
    "crudForm.field.IDschedule.placeholder": "Enter ID schedule",
    "form.field.commission.general_cargo.to": "W/m to",
    "indicator.pointOfDestination.arrival.plan": "Point Of Destination, arrival (plan)",
    "crud.field.error.seaPort.portCantBeTerminal": "A port with terminals can't be a terminal of another port",
    "templates.documents.edit.form.default": "Default Template",
    "form.address.defaultPlaceholder": "Enter city name",
    "crud.field.error.geoObject.parentGeoObjectExists": "Unable to delete a parent geo object ",
    "crud.field.password.title": "Password",
    "deliveryOrders.totalCost": "Cost",
    "modal.contactUs.phone": "Phone",
    "partner.rates.air": "Air freight rates",
    "deliveryOrders.orderList.status.bookingReceived": "Booking Received",
    "crudForm.field.charging": "Billing type",
    "crudForm.field.minCostForTransport": "Min. freight cost",
    "form.field.typeTo": "Type of arrival point",
    "detailsOfCalculation.transportationParameters": "Gross weight and dimensions",
    "crud.field.personName.title": "Person Name",
    "crudForm.field.common.documents.typeOfDocument": "Document's type",
    "documents.email.success": "The document was successfully sent by e-mail",
    "crud.field.error.railway.railwayStationTariffExists": "Unable to delete a railway station as rail transportation tariffs for it have been uploaded in the system",
    "crudForm.field.costForHour": "Cost per hour",
    "constants.customHouse.inPort": "Customs clearance at the port of unloading",
    "orderForm.numberOfContainers": "Containers:",
    "crudForm.dictionary.btn.addHatch": "Add Compartment",
    "wizard.field.containersOverweight": "Overweight",
    "crud.field.description.title": "Description",
    "crud.filter.selectFilter": "Select filter...",
    "partner.generalInformation.form.updateSuccess": "Data was successfully saved",
    "modal.changePassword.passwordConfirmation.placeholder": "Enter new password",
    "crudForm.field.hub": "Hub",
    "wizard.parameters.title": "Parameters",
    "operatorAdmin.customsCalculator": "Customs calculator (Russia)",
    "partner.users": "Users",
    "form.field.lastName.placeholder": "Enter last name",
    "crudForm.field.customsClearance.included": "Included in the tariff",
    "page.expiredTariffs.port-services": "Port charges",
    "importTranslates.alertNotSelectCondition": "Select a column with condition values",
    "page.expiredTariffs.freight-surcharges": "Freight surcharges",
    "modal.orderInfo.error": "Error",
    "crudForm.field.interestType": "Type of cargo",
    "trunking.plato.saveBtn": "Save",
    "site.header.phone": "Phone",
    "crud.field.relativeRate.title": "Rate, (%)",
    "detailsOfCalculation.from": "from",
    "indicator.pointOfOrigin.arrival.plan": "Point Of Origin, arrival (plan)",
    "importTranslates.name.ru": "Name (RU)",
    "partner.geography.form.country": "Country",
    "customsOfCargo.form.addArticles": "Add goods",
    "reportResource.report.countFail": "Number of unsuccessful requests",
    "crud.form.edit.title": "Edit entry",
    "deliveryOrders.orderBlock.orderActions.editLogistic": "Tracking",
    "crud.field.allCountries.title": "All countries",
    "pallet.too.many.directions": "The file contains too many lanes ",
    "ru.cargobar.imports.tariff.ContainerSeaFreightTariffImportServic.port.from.empty": "Invalid code for the port of departure. Row {0}, column {1}.",
    "common.roleAdmin": "Administrator of company",
    "crudForm.dictionary.field.maxFloorPressure": "Permissible floor pressure, (kgf/m2)",
    "TRANSPORT_HUB": "Point",
    "detailsOfCalculation.askInputdetailOfOrder": "If you can fill in the fields below, please do so, if not – we shall do it for you as soon as we receive the information from you by email. Your data will be saved in the system to avoid duplication in case of future shipments.",
    "constants.typeOfCargo.fcl": "FCL",
    "crudForm.field.weightFrom": "From (kg)",
    "crud.field.price.title": "Quotes",
    "toHomepage": "To main page",
    "form.wizard.title.customsOfCargo": "Point of customs clearance",
    "confirmationCode.notFound": "Confirmation code not found",
    "subscription.tariff.create.modal.title": "Choose subscription option",
    "modal.orderInfo.toLocation": "To location",
    "deliveryOrders.order.commonInfoTitle": "General Information",
    "form.order.notifyParty": "Notify party",
    "crud.field.generalCargoRate.title": "Less-than-container/truckload cargo",
    "crud.field.limitCalculations.title": "Number of quotations",
    "common.info": "Information",
    "constants.modifier.public": "Public",
    "detailsOfCalculation.alert.invalid": "The calculation is invalid, please сontact us",
    "crudForm.field.forBL": "per B/L",
    "crud.field.arrivalDataNotFill": "Consignee data is not filled",
    "wizard.insuranceForm.include": "Include",
    "constants.month.september": "September",
    "form.order.title": "Booking",
    "crud.field.counterUsePartnersApi.title": "External partners (queries / tariffs received)",
    "importTranslates.resultCountSuccess": "Successfully imported:",
    "crud.field.error.geoObject.seaportExists": "Unable to delete a geo object since railway stations are entered for it in the system",
    "site.footer.subscribe.error": "Error. Please try again later",
    "operatorAdmin.directories.seaConditions": "Sea Freight terms and conditions",
    "operatorAdmin.menu.directories.seaConditions":"Sea Freight",
    "operatorAdmin.menu.directories.airlineDisclaimers":"Air Freight",
    "operatorAdmin.menu.directories.railwayDisclaimers":"Railway Transportation",
    "operatorAdmin.menu.directories.vehicleDisclaimers":"Trucking charges",
    "passwordRecovery.title": "Password recovery",
    "partner.bankDetails.form.field.ogrn.placeholder": "Enter Primary State Registration Number",
    "common.currency": "Currency",
    "wizard.cargo.form.size.height.placeholder": "H",
    "passwordRecovery.hint": "Forgot password?",
    "operatorAdmin.personalOrders": "Bookings",
    "form.field.minKM": "Min. distance",
    "site.leftMenu.link.personalArea": "Personal Area",
    "crud.field.conditions.title": "Conditions",
    "form.field.terminal": "Terminal",
    "operatorAdmin.directories.typesOfGeneralCargo": "Boxes/Pallets",
    "deliveryOrders.orderInfo.phone": "Phone",
    "crudForm.field.ADR_ADR": "Address ⇄ Address",
    "page.accessDenied.prevLinkText": "Return to the previous page",
    "customsOfCargo.fields.currency": "Currency",
    "LogisticDocType.BILL": "Bill of Lading/air waybill",
    "orderList.companyName": "Company name",
    "crudForm.field.thresholdWeight": "Gross weight up to",
    "partner.rates.trunking.international": "International trucking charges",
    "customsOfCargo.fields.incoterms": "Incoterms",
    "ru.cargobar.imports.tariff.ContainerSeaFreightTariffImportServic.fifo.containers": "No cost of transportation. The string {0}.",
    "detailsOfCalculation.orderTable.kg": "kg",
    "wizard.directionForm.railway": "Railway station",
    "trunking.form.counterFirstString": "Successfully uploaded",
    "crud.remove.confirm.text": "Are you sure you want to delete the selected item?",
    "crudForm.dictionary.field.translate.placeholder": "Enter translation",
    "ru.cargobar.imports.tariff.ContainerSeaFreightTariffImportServic.sealine.not.found": "There is no such shipping line in the system. Row {0}, column {1}.",
    "partner.bankDetails.form.field.corrAccount": "Corresponding account number",
    "subscription.tariff.create.request": "Apply",
    "form.order.email": "Email",
    "detailsOfCalculation.orderCreated.header": "Your booking has been created and saved in our system.",
    "crud.field.forwarder.title": "Forwarder",
    "crudForm.field.generalCargoUnit": "Unit",
    "crud.field.destinationPort.title": "Port of destination",
    "common.documents.attachedDocumentsTitle": "Attached documents",
    "crudForm.dictionary.field.id": "ID",
    "crud.field.historyBillingList.title": "Subscription track record",
    "crud.field.portType.title": "Type",
    "crudForm.field.region": "Region",
    "crudForm.field.type": "Type of service",
    "subscription.switchToFree.btn.text": "Switch to the free subscription",
    "popupCreateOrder.header": "Important!",
    "wizard.subscribe.subscribeBtnText": "Subscribe",
    "deliveryOrders.confirmOfCancelOrder": "Are you sure you want to cancel this order?",
    "wizard.cargo.form.palletTypes": "Pallet",
    "common.documents.removeActionTitle": "Remove",
    "report.cargo.container": "Container",
    "constants.placeTypes.airportHub": "Airport",
    "detailsOfCalculation.successOrder.btnOk": "Go to orders",
    "orderForm.arrivalDate": "Arrival Date:",
    "crud.uploadFromExcel.example.header": "Warning!",
    "crudForm.field.work": "Valid",
    "partner.generalInformation.form.field.conversionPercent.placeholder": "Enter conversion percent",
    "deliveryOrders.orderBlock.orderActions.edit": "Edit",
    "crud.field.organization.name.title": "Company name",
    "crudForm.field.cost": "Cost",
    "client.successUpdate": "Client's data was updated",
    "crud.field.error.geoObject.airportExists": "Unable to delete a geo object since sea ports are entered for it in the system",
    "tracking.fields.error.update": "Save error",
    "crud.field.file.title": "File",
    "wizard.cargo.form.box.title": "Boxes/Pallets",
    "crud.field.seaLineOrConsolidator.title": "Shipping line/ Coloader",
    "site.copyright": "All rights reserved",
    "crudForm.field.from": "Valid from",
    "common.siteUsesCookie": "Agorafreight uses cookies to make the website more convenient. You automatically accept The Policy if you continue to use the website.",
    "wizard.cargo.form.size.width": "Width",
    "partner.freightSurcharges": "Freight surcharges",
    "subscription.history.idContract": "Contract ID",
    "crud.filter.userFilter": "Custom filter",
    "crud.field.railwayCompany.title": "Railway operator",
    "form.field.TNVD": "HS code",
    "operatorAdmin.directories.lines": "Shipping Lines",
    "common.required": "This is a required field",
    "crud.field.usageLimit.title": "Requests amount (used / total)",
    "role.partner": "Partner",
    "crud.field.middleName.title": "Middle Name",
    "deliveryOrders.mainInfo.comment": "Comments",
    "modal.currentUserInfo": "User profile",
    "deliveryOrders.calculationInfo": "Costs included:",
    "crud.field.maxWeight.value.title": "Max weight",
    "crud.field.departureData.title": "Shipper",
    "container.oversized.cargo": "Oversized/ overweight cargo –  not suitable for standard equipment – our service department will contact you shortly to discuss the best options.",
    "form.error.commission.minComissionInvalid": "Minimal forwarder's commission value can't be more than maximum one",
    "form.field.general": "General cargo",
    "modal.contactUs.question": "Your question",
    "common.field.before": "to",
    "crud.field.code.title": "Code",
    "calculations-list.guest.result.registrationMsg": "Please, register for the best freight rates.",
    "form.field.commission.integrator.menu": "Integrator",
    "form.field.organization.legalAddress": "Address of registration",
    "crud.field.maxPalletCount.title": "Max Pallet Count",
    "templates.documents.type.orderkp": "Commercial offer",
    "CMR_WITHOUT_SYMBOL": "CMR",
    "partner.geography.departureRegions": "Regions of departure",
    "crudForm.field.nds.placeholder": "Enter VAT",
    "form.field.commission.general_cargo.price": "For w/m",
    "form.field.companyName": "Company Name",
    "form.field.cargoName": "Cargo description",
    "partner.bankDetails.form.field.kpp": "RRC (registration reason code)",
    "deliveryOrders.orderDate": "Order Date",
    "person.calculationInfo.invalid": "Calculations are invalid, please click on the icon to update them",
    "constants.typeOfCargoFull.fcl": "FCL",
    "crud.field.conversionPercent.title": "Conversion Percent",
    "constants.surchargeTypes.onFreight": "For freight",
    "service.dataBaseIsUnavailable": "Database is temporarily not available",
    "crudForm.field.airline": "Airline",
    "crudForm.field.weightLimit": "Maximum permissable cargo payload ",
    "templates.documents.btn.downloadTestPdf": "View in PDF",
    "form.field.organization.name.placeholder": "Enter name",
    "dialog.confirm.yes": "Yes",
    "report.vehicle.refrigerator": "Refrigerator",
    "person.button.enter": "Personal Area",
    "trunking.form.uploadDesc": "The file must be in .xlsx format",
    "RAILWAY_NUMBER": "Railway Bill:",
    "reports.barChart.SPECIAL_REGION": "Special regions",
    "deliveryOrders.orderList.status.bookingConfirmed": "Booking Confirmed by the Shipper",
    "logistBlock.deliveryToRecipientDate": "Date of delivery to the recipient",
    "deliveryOrders.listItem.loadingDate": "Loading Date",
    "common.documents.uploadActionTitle": "Upload",
    "common.invalidSize": "Value is too long",
    "crud.field.roles.title": "Roles",
    "crudForm.field.zipCodes": "ZIP codes",
    "deliveryOrders.orderInfo.contactPerson": "Contact Person",
    "importTranslates.column": "Column №",
    "crud.field.consolidatedCargo.title": "LCL",
    "mainMenu.subscriptions.userRequests": "Clients applications",
    "crud.field.coordinates.title": "Latitude/Longitude",
    "wizard.directionForm.seaPort": "Sea port",
    "partner.geography.geoTypes.region": "Region",
    "common.add": "Add",
    "constants.insuranceTransportType.air": "Air",
    "indicator.pointOfPickup.ready.fact": "Point Of Pickup, ready (fact)",
    "crudForm.field.franchise": "Franchise",
    "form.order.user.phone": "Your phone number",
    "form.wizard.summary.title": "Form with cargo parameters",
    "crud.field.nextBillingDate.title": "Date of the next payment",
    "order.createNewManual.detailsOrder.title": "Booking Details",
    "detailsOfCalculation.fields.trunking": "Trucking",
    "crud.disclaimers.airline.airportName": "Airport",
    "detailsOfCalculation.transitTimeZero": "less than 24 hours",
    "crud.field.lastName.title": "Last Name",
    "importTranslates.columnCondition": "Condition values",
    "order.customs.already.exists": "Customs payments calculation for this cargo is already available",
    "crud.field.currency.title": "Currency",
    "templates.documents.table.headers.type": "Template type",
    "constants.charging.fromAirport": "From/to airport",
    "operatorAdmin.directories": "Add terms and conditions",
    "operatorAdmin.directoriesSuperAdmin": "Directories",
    "detailsOfCalculation.fields.priceIncludingVat": "Price including VAT",
    "form.field.cargoName.placeholder": "Enter cargo description",
    "crud.field.arrivalPlace.button.delete": "Delete consignee details",
    "detailsOfCalculation.callManager.title": "Send request",
    "orderForm.downloadFrom": "Download from a template",
    "general.cargo.compound.to.container.40": "It is more cost-effective to use 20' full-container (FCL) for this amount of cargo. Do you want to change to FCL?",
    "orderForm.cargoWeight": "Total weight:",
    "crudForm.field.portsOfArrival": "To",
    "crudForm.field.commissionNotIncluded": "Not Enabled",
    "crud.tab.organization.contact": "Full Contact Details",
    "detailsOfCalculation.successOrder.btnClose": "Close",
    "tracking.fields.events": "Events",
    "report.transportation.rail": "railway transportation",
    "crudForm.dictionary.field.kind_containerType.placeholder": "Container Kind",
    "deliveryOrders.listItem.totalPrice": "Price",
    "detailsOfCalculation.orderTable.from": "From",
    "wizard.button.search": "Search",
    "crud.field.toLocation.title": "To",
    "crudForm.field.intraportForwarding.addRange": "Add Range",
    "crud.field.contactPerson.title": "Contact person",
    "passwordRecovery.setNewPassword": "Set a new password",
    "importTranslates.typeCondtion": "Type condition",
    "crudForm.field.airTransportation.addRange": "Add Range",
    "crud.field.documentAttachments.title": "Attached documents",
    "site.header.dropdown.edit": "Edit",
    "form.order.dateReady": "Cargo readiness date",
    "subscription.request.status.DECLINE": "Declined",
    "RAILWAY_WITHOUT_SYMBOL": "Railway Bill",
    "detailsOfCalculation.orderTable.weight": "Weight",
    "partner.bankDetails.form.field.kpp.placeholder": "Enter RRC (registration reason code)",
    "wizard.wrongDirection": "Rates via this route are currently unavailable on-line",
    "modal.orderInfo.container": "Container",
    "partner.title": "Company Information",
    "crud.field.cost.title": "Cost",
    "form.wizard.seo.description": "Get online quotation with Agora Freight online cargo delivery calculator. Get instant quotes, compare, and book online!",
    "person.detailsOfCalculation": "Details of calculation",
    "crud.field.periodEnd.title": "Period (until)",
    "deliveryOrders.orderList.status.delivered": "Delivered",
    "form.field.commission.addStr.private": "Private range",
    "page.accessDenied.loginLinkText": "Proceed to login page",
    "detailsOfCalculation.filters": "Filters",
    "acts.page.title": "Invoices and Statements",
    "crud.field.countryId.title": "Country",
    "confirm.success.text": "Registration has been successfully completed!",
    "crudForm.field.commentDescription.placeholder": "Description",
    "detailsOfCalculation.fields.total": "Total",
    "templates.documents.page.title": "Document Templates",
    "partner.rates.pallets": "Pallets",
    "deliveryOrders.singleOrderTitle": "Delivery order",
    "logistBlock.invoice": "Invoice",
    "OF_ARRIVAL": "оf arrival",
    "crudForm.field.minPrice": "Min. price",
    "general.cargo.compound.to.container.20": "It is more cost-effective to use 40' full-container (FCL) for this amount of cargo. Do you want to change to FCL or leave it as LCL?",
    "role.client": "Client",
    "crud.field.costCargo.title": "Cost",
    "constants.routeType.localArrival": "Local (arrival)",
    "crud.filter.active": "Active",
    "crudForm.field.transitSurcharge": "Transit surcharge",
    "crud.field.error.geoObject.transportHubsExists": "Unable to delete a geo object since transport hubs are entered for it in the system",
    "crud.field.billingDate.title": "Date of subscription",
    "dictionary.translation.noSuchField": "Field does not exist in the directory",
    "crud.field.seaPortId.title": "Port",
    "crud.field.error.airport.airTariffExists": "Unable to delete an air port since airport charges have been uploaded for it",
    "deliveryOrders.orderId": "Order number",
    "waiting.text": "Wait",
    "crud.field.externalAPIAgora.title": "AGORA API",
    "crud.tab.mainParams": "Main parameters",
    "AWB": "AWB:",
    "customsOfCargo.fields.name": "Code/Name",
    "organizationsProfiles.approveProfileConfirm": "Are you sure you want to confirm the profile?",
    "reportResource.report.countSuccess": "Number of successful requests",
    "form.order.truckCount": "Number of trucks",
    "crudForm.dictionary.field.translate": "Translation",
    "importTranslates.resultCountError": "Import errors:",
    "common.close": "Close",
    "crudForm.default.title": "Form",
    "crudForm.field.IDschedule": "ID schedule",
    "ru.cargobar.imports.tariff.ContainerSeaFreightTariffImportServic.port.to.empty": "Invalid code for the port of arrival. Row {0}, column {1}.",
    "subscription.page.config.resetFree": "Interval for free quotations counter (free-subscription)",
    "partner.bankDetails.form.field.fullName": "Name of your legal entity",
    "crud.field.billRate.title": "Cost for B/L",
    "partner.generalInformation.form.field.conversionPercent": "Conversion percent",
    "reports.types.USER_COUNT": "Number of registered users",
    "trunking.form.downloadTitle": "View recently uploaded tariffs",
    "crudForm.bulkOperations.field.filter": "For all filtered items",
    "reports.types.POPULAR_DESTINATIONS_TREND_CARGO": "Popular destinations (in relation to the previous period, by types cargo)",
    "crudForm.field.airports": "Airports",
    "subscription.tariff.period": "/ month",
    "crud.field.airline.title": "Airline",
    "crud.field.geoCoordinates.title": "Latitude/Longitude",
    "widget.completed": "completed",
    "reports.types.ORDER_COUNT": "Number of requested calculations",
    "constants.vehicleDistanceRange.OVER_100": ">100 km",
    "crud.field.email.title": "Email",
    "user.notFound": "User not found",
    "crud.field.requestGroup.id.title": "Order number",
    "subscription.modal.add.btn.label": "Subscription",
    "common.documents.renameActionTitle": "Rename",
    "constants.typeOfCargo.lcl": "LCL",
    "crudForm.field.intraportForwarding.placeholder": "Enter data",
    "partner.userInfo.title": "Partner info",
    "crud.field.regions.title": "Regions",
    "orderForm.addSide": "Add notify party",
    "crud.field.organizationId.title": "Company name",
    "crudForm.dictionary.field.maxLoad": "Limit Load, (kg)",
    "wizard.cargo.shipmentTotal": "Shipment total",
    "crud.disclaimers.airline.airlineName": "Airline",
    "indicator.invoicePaymentDate": "Invoice payment date",
    "detailsOfCalculation.detailInformation": "Detailed Information",
    "crud.disclaimers.vehicle.cityFromName": "From",
    "form.field.enter.placeholder": "Enter the data",
    "crudForm.field.accessLevel": "Access level",
    "detailsOfCalculation.fields.service": "Service",
    "wizard.cargo.totalWeight": "Total weight",
    "importTranslates.name.en": "Name (EN)",
    "notifications.title": "Notifications",
    "deliveryOrders.status.NEW": "New",
    "deliveryOrders.orderBlock.orderActions.changeOwner": "Change owner",
    "templates.documents.alert.beforeSave": "To ensure the file is correct, review the template before saving by clicking:",
    "importTranslates.btn.import": "Import",
    "crudForm.error.tariff.invalidDateFrom": "The tariff can't expire before its starting validity date",
    "crudForm.field.pricePerCbm": "Price per cubic meter",
    "subscription.history.tariff": "Subscription",
    "detailsOfCalculation.calculationsIsEmpty.top": "Apologies, although we are constantly developing the geographical coverage of our system, not every calculation can be provided instantly as in this case.",
    "report.transportation.multimodal.avia": "multimodal transportation (air + road)",
    "reportResource.report.countCommon": "Number of requests",
    "form.field.country": "Country",
    "trunking.replaceFile": "Upload new file",
    "error.kpp.size.9": "Enter the correct RRC (9 digits)",
    "form.field.commission.general_cargo.from": "W/m from",
    "form.field.firstName": "First Name",
    "detailsOfCalculation.save": "Save",
    "constants.charging.ADR_RAILWAY": "Address ⇄ Railway",
    "partner.forwarders": "Forwarders",
    "agreement.title": "Terms of use",
    "report.vehicle.term": "Thermo",
    "crud.filter.inactive": "Inactive tariffs",
    "crudForm.field.calculationType": "Quote type",
    "form.wizard.header": "Receive your quotation",
    "modal.orderInfo.result": "Result",
    "login.submit.facebook": "Login with Facebook",
    "customsOfCargo.navTitle": "Goods",
    "form.field.commission.port_agent.menu": "Port agent",
    "partner.additions": "Surcharges",
    "LogisticDocType.CERTIFICATE": "Certificate",
    "form.order.phone": "Phone",
    "partner.rates.billOfLading": "Bill of lading",
    "crudForm.field.commissionType": "Commission type",
    "crudForm.field.rates": "Rate",
    "form.order.volume": "Cargo volume",
    "crud.field.ABSOLUTE": "Absolute",
    "crudForm.field.price": "Price",
    "palletTariff.form.addFile": "Attach file",
    "deliveryOrders.listItem.arrivalDate": "Arrival Date",
    "partner.userInfo.form.firstName": "First Name",
    "partner.bankDetails.form.field.bik.placeholder": "Enter BIK number",
    "indicator.pointOfOrigin.arrival": "Point Of Origin, arrival",
    "crud.field.country.from.title": "Country from",
    "reports.lineChart.countOfInWork": "In Progress",
    "crudForm.field.forCbm": "per m3",
    "partner.generalInformation": "About Us",
    "constants.vehicleDistanceRange.UNDER_50": "<50 km",
    "constants.charging.AIR_ADR": "Airport ⇄ Address",
    "crud.disclaimers.vehicle.cityToName": "To",
    "templates.documents.edit.form.btn.file": "Attach file",
    "crud.field.infinite.title": "Unlimited quotations",
    "crud.remove.success": "Element was removed",
    "tracking.fields.eventType": "Event type",
    "crud.field.error.seaLine.portTariffExists": "A coloader can't  be deleted as their port charges are in the system",
    "form.field.headerModalSettingsScan": "Popular destinations search options settings",
    "passwordRecovery.submit": "Recover password",
    "crudForm.field.minSum": "Minimal amount",
    "reports.field.dateFrom": "From",
    "wizard.tooltip.selectContainer40": "Please view 40ft container options.",
    "constants.customHouse.inDestinationPlace": "Customs clearance at the final destination",
    "common.documents.title": "List of all documents in the system",
    "crud.form.edit.template.title": "Edit template",
    "crud.field.objectId.title": "Object ID",
    "constants.vehicleBodyType.term": "Thermo",
    "partner.geography.form.city": "City",
    "detailsOfCalculation.calculationReportHeader": "Calculation report №",
    "form.order.shippingDatePlan": "Cargo readiness date, plan",
    "contacts.shipper": "Shipper",
    "partner.geography": "Geography of presence",
    "form.field.commission.load_error": "No profiles specified",
    "crudForm.field.radiusTariff": "Distance based tariff",
    "importTranslates.importFile": "Import the file",
    "crudForm.field.detention": "Detention",
    "partner.rates.zip": "ZIP",
    "crud.filter.nds.label": "VAT (include / exclude)",
    "crudForm.field.period": "Period",
    "form.order.consignee": "Consignee",
    "order.createNewManual.title": "Place a new booking",
    "operatorAdmin.directories.countryCategories": "Insurance. Country categories",
    "crud.disclaimers.railway.railwayCompanyName": "Railway operator",
    "pallet.not.tariffs": "No tariffs found",
    "indicator.customsClearance.arrival.plan": "Customs Clearance, arrival (plan)",
    "common.save.success": "Data saved successfully",
    "crud.field.consolidator.title": "Coloader",
    "detailsOfCalculation.download": "Download",
    "disclaimers.title.modal": "Additional terms",
    "crudForm.dictionary.field.code_lang.placeholder": "Enter the language code",
    "crudForm.field.commentDescription": "Description",
    "subscription.request.status.PROCESSED": "In progress",
    "orderForm.estPrice": "Rate/Planned:",
    "crud.field.CONTAINER": "FCL",
    "form.field.user": "User",
    "LogisticDocType.Offer": "Offer",
    "login.welcome": "Please login.",
    "form.field.commission.profile1.menu": "Road transportation",
    "person.quotation.reference": "Quotation reference №",
    "wizard.tooltip.selectContainer20": "Please view 20ft container options.",
    "operatorAdmin.directories.customsClearance": "Customs clearance",
    "report.vehicle.tent": "Tautliner",
    "partner.rates.billOfAir": "Air waybill",
    "reports.lineChart.countOfClosed": "Closed",
    "crudForm.field.edit": "Edit",
    "orderForm.modal.numberOfPlaces": "Number of places:",
    "operatorAdmin.directories.lineConditions": "Liner terms",
    "common.loading": "Loading",
    "constants.typeOfCargoFull.lcl": "LCL",
    "templates.documents.table.headers.actions": "Actions",
    "crud.field.moderationRequired.title": "Moderation Required",
    "operatorAdmin.audit": "Audit",
    "subscription.request.status.ACCEPT": "Approved",
    "crudForm.bulkOperations.form.info.email": "You can enter multiple mail addresses with a space",
    "excel.tariff.already.exist": "Excel tariff already exists for this lane",
    "crud.field.summary.title": "Number of requested calculations",
    "common.documents.addBtnTitle": "Add document",
    "report.cargo.vehicle": "Vehicle",
    "common.documents.error.id": "There are no bookings  with the indicated number",
    "common.greaterThanOne": "Value should be greater then 1",
    "order.location.airportId.required": "This is a required field",
    "crud.disclaimers.airline.conditionText": "Disclaimer",
    "modal.currentUserInfo.success": "Profile data was updated",
    "partner.rates.excel.tariffs": "Excel tariffs",
    "deliveryOrders.status.COMPLETED": "Completed",
    "crudForm.field.routeType": "Route type",
    "form.field.importer": "Importer",
    "TTH": "Transport document:",
    "constants.semiannual": "Six months",
    "crudForm.dictionary.field.code": "Code",
    "partner.rates.intraportForwarding": "Handling at the port",
    "deliveryOrders.status.PICKED_UP": "Picked up",
    "tracking.already.exists": "Tracking for this cargo is already available",
    "crudForm.dictionary.field.code_currency": "Currency code",
    "ratingReport.noResult": "of which with no results",
    "common.greaterThanZero": "Value should be greater then 0",
    "indicator.pointOfArrival.arrival.plan": "Point Of Arrival, arrival (plan)",
    "form.field.organization.legalAddress.placeholder": "Enter address of registration",
    "constants.vehicleBodyType.tent": "Tautliner",
    "crud.field.airport.title": "Airport",
    "reports.lineChart.count": "Quantity",
    "detailsOfCalculation.alert.containsArtificialCalculation": "Some parts of the quotation should be clarified. Please click here for clarification.",
    "detailsOfCalculation.orderDetails.btnName": "Additional info",
    "notifications.type.ExpiredCalculation.title": "Calculation № order.Id is out of date.",
    "constants.ordersSortFilter.bestTime": "Time of delivery",
    "form.field.includeInCost": "Include in the rate",
    "form.field.phone.placeholder": "Enter phone number",
    "crud.disclaimers.railway.cargoTypeName": "Cargo type",
    "partner.bankDetails.form.field.bankName": "In the Bank",
    "ordersList.button.getMaxInfo": "Display in an expanded form",
    "crud.field.status.title": "Status",
    "reports.barChart.REGION": "Regions",
    "indicator.pointOfPickup.departure": "Point Of Pickup, departure ",
    "detailsOfCalculation.filters.remove.text": "Successful search parameters have been cleared",
    "crud.field.selectTemplate.title": "Select template",
    "constants.ordersSortFilterCurrency.default": "MULTI",
    "form.wizard.title.cargo": "Cargo",
    "userRegistration.uniqueEmail": "E-mail address is already registered in the system",
    "person.delivery.status.title": "Status",
    "operatorAdmin.directories.statuses": "Status",
    "constants.vehicleBodyType.refrigerator": "Refrigerated",
    "common.documents.error.file": "File size over 20 MB",
    "wizard.insuranceForm.value": "Value",
    "crud.field.factAddress.title": "Fact Address",
    "orderForm.warning.shipperInfoMessage": "Shipper details",
    "passwordRecovery.link": "Recovery",
    "ru.cargobar.imports.tariff.ContainerSeaFreightTariffImportServic.date.to": "Wrong expiry date of the tariff. Row {0}, column {1}.",
    "indicator.deliveryToRecipientDate.fact": "Delivery To Recipient (fact)",
    "tracking.fields.place": "City",
    "partner.rates": "Tariffs",
    "form.field.attachmentDocuments": "Attached documents",
    "operatorAdmin.directories.documentsFlow": "Document flow",
    "operatorAdmin.listOfAPIsAgora": "External APIs (Agora)",
    "crud.field.resetUsageLimit.title": "Reset the number of used requests",
    "crudForm.dictionary.field.id.placeholder": "ID",
    "reports.lineChart.countOfCanceled": "Canceled",
    "wizard.cargo.form.vehiclePayload": "Trailer type",
    "detailsOfCalculation.railway_station_departure": "Departure station charges",
    "detailsOfCalculation.fields.sellerReward": "Seller's commission",
    "crudForm.field.schedule": "Schedule",
    "templates.documents.table.headers.lang": "Language",
    "crud.field.countVariantsCalculations.title": "Number of calculation options",
    "detailsOfCalculation.calcID": "Quotation reference",
    "partner.bankDetails.form.field.address": "Legal address",
    "constants.ordersSortFilterCurrency.rub": "RUB",
    "partner.services.serviceTypes.serviceCategories": "Categories of services",
    "subscription.main.infinite.text": "Unlimited number of quotations:",
    "crud.field.interestType.title": "Type of cargo",
    "crud.field.transitTime.title": "Estimated transit time",
    "constants.nds.zero": "0",
    "deliveryOrders.listItem.orderNumber": "Order Number",
    "crud.field.zipCode.title": "Zip code",
    "crudForm.field.thresholdPrice": "Cost per kg",
    "customsOfCargo.fields.nds": "VAT, 20%",
    "RAILWAY": "Railway Bill:",
    "pallet.tariff.not.found.ranges": "Failed to determine ranges for international shipment",
    "crud.field.port.title": "Port",
    "crud.field.uid.title": "UID",
    "crudForm.field.notWork": "Invalid",
    "form.field.limitDays": "number of days for the report",
    "partner.userInfo.form.email": "Email",
    "crud.uploadFromExcel": "Upload from an Excel file",
    "crud.field.languageEmail.title": "Language of email notifications",
    "form.order.bill.cargoDescription": "Description of Packages and Goods",
    "detailsOfCalculation.insurance.insuranceCost": "Insurance fee",
    "operatorAdmin.directories.logisticDocumentKindTitle": "Types of transport documents",
    "dialog.confirm.no": "No, cancel",
    "crud.disclaimers.vehicle.chargingName": "Billing type",
    "modal.orderInfo.general": "Less-than-container/ truckload cargo",
    "crud.field.modifier.private": "Private",
    "crud.field.organization.title": "Company name",
    "common.required.commonRanges": "Please add 1 range for a public rate",
    "common.documents.error.fieldsWithoutFile": "Fill in all the fields",
    "orderForm.idContainer": "Container number:",
    "radius.delete": "Delete",
    "customsOfCargo.form.price.title": "Total costs for cargo clearance",
    "modal.changePassword.oldPassword": "Old password",
    "tracking.fields.search": "Search keys",
    "constants.month": "Month",
    "reports.types.DICTIONARY_COUNT": "Number of system objects",
    "deliveryOrders.listItem.cargoType": "Cargo Type",
    "form.field.organizationName": "Company name",
    "logistBlock.loadingDate": "Loading",
    "confirm.order.success": "Your rate request has been processed",
    "directionOfTransportation.arrival": "Arrival",
    "partner.generalInformation.form.field.profileStatus.APPROVED": "Approved",
    "crud.field.countryTo.title": "To",
    "wizard.directionForm.title": "Please select one of the following options:",
    "constants.routeType.import": "Import",
    "constants.ordersSortFilter.bestPrice": "By price",
    "operatorAdmin.clients": "Clients",
    "crudForm.bulkOperations.nameCode.addSurcharges": "Add surcharges",
    "widget.order.number": "Your order number",
    "crud.filter.hide": "Hide",
    "crudForm.field.reverseTariff": "Reverse tariff",
    "trunking.form.counterLastString": "tariffs.",
    "person.calculationInfo.update": "Update",
    "constants.charging.betweenTheCityPerDistance": "Between cities (per km)",
    "reports.field.dateTo": "To",
    "deliveryOrders.orderList.status.standby": "Standby",
    "user.passwordChanged": "Password changed successfully",
    "reports.barChart.CURRENCY": "Currency",
    "form.select.defaultPlaceholder": "Select...",
    "form.field.postcode.placeholder": "Enter postcode",
    "deliveryOrders.orderInfo.address": "Address",
    "ru.cargobar.imports.tariff.ContainerSeaFreightTariffImportServic.docdeadline": "The deadline for submission of documents is incorrect. Row {0}, column {1}.",
    "ratingReport.noOrderData": "Deleted quotation",
    "modal.orderInfo.vehicle": "Vehicle",
    "person.order.number": "Order №",
    "crudForm.field.to": "Valid to",
    "form.field.shippingDate": "Cargo readiness date (if known)",
    "reports.lineChart.countOfOrder": "Number of requested calculations",
    "detailsOfCalculation.orderDetails.title": "Additional info",
    "form.wizard.title.cargoItem": "Item",
    "common.roleManager": "Manager of company",
    "constants.charging.ADR_ADR": "Address ⇄ Address",
    "site.user.logoutSuccess": "You have successfully logged out of your account",
    "crud.field.category.title": "Category",
    "operatorAdmin.directories.interestTypes": "Insurance. Categories of cargo",
    "partner.generalInformation.form.field.legalAddress.placeholder": "Enter your legal address",
    "crudForm.bulkOperations.error": "Error",
    "crud.field.hatchSize.title": "Aircraft hatch dimensions",
    "crud.field.enableLimit.title": "Include requests limit",
    "crud.field.timeOfAction": "Validity",
    "operatorAdmin.directories.additions": "Surcharge",
    "subscription.page.config.enable": "State of subscription system (disabled/enabled)",
    "deliveryOrders.cargoInformation.cargoSizeTitle": "Size",
    "crudForm.field.ports": "Ports",
    "wizard.customsOfCargo.containerRP": "container",
    "deliveryOrders.cargoTrackingTitle": "Tracking",
    "deliveryOrders.orderList.headerTable.totalPrice": "Price",
    "ratingReport.pressBtnForGenerateReport": "Click \"Generate report\" to get the data",
    "crudForm.field.seaLines": "Shipping line",
    "page.expiredTariffs.title": "Dear user some of your tariffs are about to expire",
    "reportResource.report.countTariffs": "Number of tariffs received",
    "crud.field.firstName.title": "First Name",
    "logistBlock.export": "Departure from POD",
    "importTranslates.select.sheet": "Select sheet",
    "crudForm.dictionary.field.code_lang": "Language code",
    "service.reloadThePage": "Reload the page",
    "constants.weightUnits.lb": "lb",
    "crud.field.modifier.public": "Public",
    "crud.field.error.railway.railwayTariffExists": "Unable to delete a railway station as railway station charges for it have been uploaded in the system",
    "HOME_BOL_WITHOUT_SYMBOL": "House Bill of Lading",
    "trunking.international.form.defaultFileName": "International trucking charges tariff",
    "register.link.text": "If you do not have an account",
    "form.order.contacts": "Your contact details",
    "indicator.receiptOfDocuments": "Point Of Arrival, receipt of documents",
    "crudForm.field.intraportForwarding.container.from": "Number of containers from",
    "partner.geography.officeAndBranches": "Offices and branches",
    "crudForm.field.palletType": "Pallet type",
    "indicator.customsClearance.departure": "Customs Clearance, departure",
    "partner.rates.airportServices": "Airport charges",
    "HOME_AWB_WITHOUT_SYMBOL": "House AWB",
    "tracking.fields.key": "Key",
    "logistBlock.fact": "Fact",
    "crudForm.dictionary.field.coordinates.latitude.placeholder": "Latitude",
    "crud.field.condition.title": "Text of additional terms",
    "crud.field.error.airport.roadTariffExists": "Unable to delete an airport since air tariffs have been uploaded for it",
    "crudForm.dictionary.field.description": "Description",
    "constants.weightUnits.kg": "kg",
    "detailsOfCalculation.calcFounded": "Options available:",
    "contacts.confirm.success": "Notified party deleted",
    "form.field.seaPort": "Select sea port",
    "crudForm.field.common.documents.file": "Download a new file in any format, not more than 20 MB",
    "form.field.countryNew": "Start typing the name of the country and choose from the dropdown list",
    "partner.generalInformation.form.field.name": "Name",
    "crud.field.surcharge.code.title": "Code",
    "subscription.modal.add.title": "New subscription application",
    "reports.lineChart.countOfAuto": "Road transportation",
    "HOME_BOL": "House Bill of Lading:",
    "crudForm.field.serviceType": "Service type",
    "form.field.commission.placeholder": "Enter data",
    "wizard.cargo.title": "Cargo",
    "wizard.cargo.form.containers.title": "Containers",
    "crud.tabs.historyCalculation": "Quotations track record",
    "pallet.no.identify.direction": "Failed to locate a major city",
    "crud.field.limit.title": "Requests (per month)",
    "trunking.plato.platoRate": "Per kilometer rate",
    "wizard.subscribe.placeholder": "Your email",
    "constants.customHouseAir.inDestinationPlace": "Customs clearance at the airport of arrival",
    "partner.personalOrders": "Bookings",
    "profile.welcome.title": "Welcome",
    "crudForm.field.nds": "VAT, (%)",
    "constants.ordersSortFilterCurrency.eur": "EUR",
    "form.field.commission.type": "Type",
    "wizard.cargo.form.containers.add": "Add",
    "form.field.shippingDate.placeholder": "Enter date of shipment",
    "crud.filter.value": "Value",
    "userRegistration.invalidConfirmationCode": "Invalid confirmation code",
    "constants.placeTypes.address": "Address",
    "templates.documents.edit.form.alert.nofile": "You must attach a file to continue.",
    "crud.field.dateCreated.title": "Date of the quotation",
    "reports.barChart.WORLD_PART": "Continents",
    "crud.field.error.geoObject.wrongLongitude": "Longitude should be within range [-180, 180]",
    "detailsOfCalculation.authLink": "Please {register} or {logIn} to see detailed information",
    "crudForm.dictionary.field.hatchSize": "Plane hatch size",
    "constants.tarifications.private": "Private",
    "indicator.receiptOfDocuments.plan": "Point Of Arrival, receipt of documents (plan)",
    "detailsOfCalculation.outdatedCalculationI.temMsg": "The rate calculation is out-of-date. Please renew to be able to book.",
    "crudForm.field.commission": "Forwarder's commission included",
    "crud.field.error.wordRegion.organizationExists": "Can't delete a geographical area since it used in company's authorized trade lanes",
    "detailsOfCalculation.saved": "Saved",
    "reports.alert.fail": "An error occurred",
    "report.transportation.carriage": "(to doors)",
    "crudForm.bulkOperations.field.selectOperation": "Action",
    "deliveryOrders.orderInfo.shipperAndConsignee": "Shipper/Consignee",
    "crudForm.field.common.documents.orderId": "Order number",
    "report.unit.tn": "t",
    "deliveryOrders.orderList.headerTable.destinationPoint": "To",
    "site.header.contactUs": "Contact us",
    "report.cargo.bill": "B/L",
    "crudForm.dictionary.field.maxWeight": "Max weight",
    "crud.field.internalPartners.title": "Internal partners",
    "crud.field.longitude.title": "Longitude",
    "crudForm.field.findTimetable": "Find a schedule",
    "constants.ordersSortFilterCurrency.usd": "USD",
    "common.btn.ready": "Ok",
    "crud.field.billing.title": "Billing",
    "wizard.customsOfCargo.containerMn": "containers",
    "trunking.form.uploadSuccess": "File successfully uploaded",
    "detailsOfCalculation.orderTable.pcs": "pcs",
    "partner.users.title": "Users",
    "detailsOfCalculation.fields.amount": "Amount",
    "register.client.title": "Register as a client",
    "crudForm.dictionary.field.comment": "Comment",
    "partner.userInfo.form.lastName.placeholder": "Enter last Name",
    "reports.barChart.CITY": "Cities",
    "notifications.new": "You have a new notification",
    "crud.field.error.seaLine.freightTariffExists": "A shipping line can't be deleted as freight surcharge has been entered for it",
    "confirm.invalid.link": "Invalid link, contact the administrator.",
    "crudForm.bulkOperations.nameCode.sendEmail": "Send email",
    "ru.cargobar.imports.tariff.ContainerSeaFreightTariffImportServic.fifo.container.40": "Invalid cost for FIFO container 40. Row {0}, column {1}.",
    "page.expiredTariffs.trunking": "Trucking rates",
    "detailsOfCalculation.fields.bl": "B/L",
    "common.status.completed": "Completed",
    "trunking.form.sendBtn": "Upload",
    "crud.tab.organization.bank": "Bank Account Details",
    "form.order.selectDate": "Cargo readiness date, plan",
    "LogisticDocType.ORDER_TO_LOGISTICIAN": "Task for a logistics manager",
    "deliveryOrders.orderList.header": "My Orders",
    "common.nextDateScan": "Date of the next full statistics update",
    "logistBlock.arrivalDate": "Arrival",
    "crud.field.subscriptionTariffPlan.title": "Subscription",
    "crud.field.externalAPI.manage.title": "Manage",
    "deliveryOrders.status.DELIVERED": "Delivered",
    "partner.bankDetails.form.field.bankName.placeholder": "Enter the name of the bank",
    "indicator.pointOfPickup.load.fact": "Point Of Pickup, load (plan)",
    "documents.replace.denied": "This document has already been uploaded by another user, it is not possible to replace it with a new one",
    "crud.tab.organization.acts": "Invoices and Statements",
    "crudForm.bulkOperations.nameCode.removeElements": "Delete items",
    "detailsOfCalculation.makeOrder": "Book",
    "crudForm.field.seaLine": "Shipping line",
    "crud.field.typeOfCargo.title": "Type of cargo",
    "customsOfCargo.fields.customsDuty": "Customs duty",
    "crud.field.worldPartId.title": "Geographical area",
    "deliveryOrders.cargoTracking.table.billing": "Billing",
    "subscription.switchToFree": "Confirm the switch",
    "crudForm.bulkOperations.nameCode.removeSurcharges": "Delete surcharges",
    "crudForm.dictionary.field.volume": "Volume, (m3)",
    "crud.field.oldVersion.title": "Old value",
    "detailsOfCalculation.fields.currency": "Currency",
    "crudForm.field.weightTo": "To (kg)",
    "ru.cargobar.imports.tariff.ContainerSeaFreightTariffImportServic.fifo.container.20": "Invalid cost for FIFO container 20. Row {0}, column {1}.",
    "orderForm.editActionTitle": "edit",
    "crud.label.no": "No",
    "crud.field.user.title": "User",
    "service.unavailable.text": "Service is not available now",
    "person.calculations": "My quotes",
    "form.field.middleName.placeholder": "Enter middle name",
    "crudForm.field.consolidator": "Coloader ",
    "common.emailInvalid": "Invalid email format",
    "crud.field.countries.title": "Countries",
    "form.field.languageEmail": "Notification language",
    "constants.month.october": "October",
    "deliveryOrders.orderList.headerTable.status": "Status",
    "importTranslates.skipRows": "Skip rows",
    "modal.manage.externalAPI": "External API Management",
    "crud.field.client.title": "Client",
    "detailsOfCalculation.loader.title": "Processing best options",
    "operatorAdmin.directories.railwayOperators": "Railway operators",
    "partner.geography.geoTypes.worldPart": "Continent",
    "templates.documents.edit.form.lang": "Template language",
    "crudForm.field.airport": "Airport",
    "common.field.from": "from",
    "SEA_PORT": "Port",
    "organizationsProfiles.status.APPROVED": "Approved",
    "crudForm.button.addEmail": "Add email",
    "detailsOfCalculation.fields.byHour": "Per hour",
    "contacts.toNotify": "Notify",
    "deliveryOrders.orderList.status.pickedUp": "Cargo Picked-Up",
    "indicator.pointOfOrigin.departure": "Point Of Origin, departure",
    "deliveryOrders.orderList.headerTable.dateReady": "Ready Date",
    "crudForm.field.regionAbsent": "Absent",
    "crudForm.field.kg": "kg",
    "partner.userInfo.form.phone": "Phone",
    "constants.placeTypes.airport": "Airport",
    "common.placeholder": "Enter data",
    "pallet.tariff.already.exist": "Pallet tariff already exists for this direction",
    "deliveryOrders.status.CANCELLED": "Cancelled",
    "templates.documents.edit.form.header.new": "New template",
    "form.field.commission.integrator.title": "Forwarder's commission",
    "form.field.confirmCode.placeholder": "Enter code",
    "indicator.pointOfOrigin.departure.fact": "Point Of Origin, departure (plan)",
    "crudForm.field.transitTime": "Estimated transit time",
    "detailsOfCalculation.fields.includeVat": "including VAT",
    "general.cargo.air.too.big": "The cargo with such parameters is oversized for air transportation. Please reduce cargo weight and dimensions or approach us for a custom quote.",
    "partner.bankDetails.form.field.numberAccount.placeholder": "Provide the account number",
    "reports.alert.success": "Action completed successfully",
    "logistBlock.departureDate": "Departure",
    "form.field.limitMounthScan": "Statistics collection period (in months).",
    "report.transportation.pickup": "(from doors)",
    "palletTariff.replaceFile": "Upload new file",
    "reportResource.report.countUseApi": "Number of queries to external API",
    "crud.filter.yes": "Yes",
    "detailsOfCalculation.defaultReportHeader": "Report",
    "templates.documents.edit.form.save": "Save",
    "detailsOfCalculation.callManager.alert": "The rate is to be clarified",
    "crudForm.dictionary.field.allowCreateCalculations4Guest":"Guest Quotations",
    "deliveryOrders.orderBlock.orderActions.moveToCalculation":"View the quotation",
    "ru.cargobar.imports.tariff.ContainerRailwayTariffImportService.currency.empty":"Currency is filled incorrectly. Row {0}, column {1}.",
    "ru.cargobar.imports.tariff.ContainerRailwayTariffImportService.currency.not.found":"There is no such currency in the system. Row {0}, column {1}.",
    "ru.cargobar.imports.tariff.ContainerRailwayTariffImportService.date.from":"Invalid tariff start date. Row {0}, column {1}.",
    "ru.cargobar.imports.tariff.ContainerRailwayTariffImportService.date.to":"Invalid tariff end date. Row {0}, column {1}.",
    "ru.cargobar.imports.tariff.ContainerRailwayTariffImportService.hub.from.empty":"Invalid departure station. Row {0}, column {1}.",
    "ru.cargobar.imports.tariff.ContainerRailwayTariffImportService.hub.from.not.found":"There is no such departure station in the system. Row {0}, column {1}.",
    "ru.cargobar.imports.tariff.ContainerRailwayTariffImportService.hub.to.empty":"Invalid arrival station. Row {0}, column {1}.",
    "ru.cargobar.imports.tariff.ContainerRailwayTariffImportService.hub.to.not.found":"There is no such arrival station in the system. Row {0}, column {1}.",
    "ru.cargobar.imports.tariff.ContainerRailwayTariffImportService.operator.empty":"Invalid railway operator code. Row {0}, column {1}.",
    "ru.cargobar.imports.tariff.ContainerRailwayTariffImportService.operator.not.found":"There is no such railway operator in the system. Row {0}, column {1}.",
    "ru.cargobar.imports.tariff.ContainerRailwayTariffImportService.transitTime":"The transit time is incorrect. Row {0}, column {1}.",
    "ru.cargobar.imports.tariff.ContainerRailwayTariffImportService.fifo.containers":"No cost of transportation. The string {0}.",
    "wizard.cargo.form.size.totalVolume":"Total Volume",
    "wizard.cargo.form.size.totalWeight":"Total Weight",
    "wizard.cargo.form.CubVolume.placeholder.M3":"Cube meters",
    "wizard.cargo.form.CubVolume.placeholder.FT3":"Cube feet",
    "report.unit.m3":"cub. m",
    "report.unit.ft3":"cub. ft",
    "deliveryOrders.cargoInformation.cargoTypeCube":"Cubes",
    "uploadFile.error.text":"During the file processing there appeared the following errors:",
    "ru.cargobar.imports.tariff.ContainerComplexTariffImportService.currency.empty":"Currency is filled incorrectly. Row {0}, column {1}.",
    "ru.cargobar.imports.tariff.ContainerComplexTariffImportService.currency.not.found":"There is no such currency in the system. Row {0}, column {1}.",
    "ru.cargobar.imports.tariff.ContainerComplexTariffImportService.date.from":"Invalid tariff start date. Row {0}, column {1}.",
    "ru.cargobar.imports.tariff.ContainerComplexTariffImportService.date.to":"Invalid tariff end date. Row {0}, column {1}.",
    "ru.cargobar.imports.tariff.ContainerComplexTariffImportService.hub.from.empty":"Invalid origin hub. Row {0}, column {1}.",
    "ru.cargobar.imports.tariff.ContainerComplexTariffImportService.hub.from.not.found":"There is no departure hub in the system. Row {0}, column {1}.",
    "ru.cargobar.imports.tariff.ContainerComplexTariffImportService.hub.to.empty":"Invalid arrival hub. Row {0}, column {1}.",
    "ru.cargobar.imports.tariff.ContainerComplexTariffImportService.hub.to.not.found":"There is no arrival hub in the system. Row {0}, column {1}.",
    "ru.cargobar.imports.tariff.ContainerComplexTariffImportService.transitTime":"The transit time is incorrect. Row {0}, column {1}.",
    "ru.cargobar.imports.tariff.ContainerComplexTariffImportService.fifo.containers":"No cost of transportation. The string {0}.",
    "crudForm.field.common.documents.logisticDoc.warning":"Prior to uploading a document of this type, fill in the data on the shipper and consignee",
    "crud.field.ownerId.title":"Owner",
    "crud.field.owner.title":"Owner",
    "wizard.directionForm.disabledButtonHint":"Choose another type of cargo",
    "files.type.logo":"Logo",
    "files.page.title":"Files",
    "files.edit.form.header.edit":"Update file",
    "files.edit.form.header.new":"New file",
    "files.edit.form.alert.nofile":"You must attach a file to continue.",
    "files.edit.form.alert.exist":"A file has already been created for these parameters.",
    "files.table.headers.name":"Name",
    "files.table.headers.type":"Type",
    "files.table.headers.actions":"Actions",
    "files.edit.form.btn.file":"Attach file",
    "files.edit.form.nofile":"File not selected",
    "files.edit.form.save":"Save",
    "files.edit.form.cancel":"Cancel",
    "crud.field.enableIntegratorReward.title":"Apply margin rules",
    "crud.field.enableIntegratorRewardAgora.title":"Apply margin rules (Agora API)",
    'crud.field.freight20.title': 'Price freight 20',
    'crud.field.freight40.title': 'Price freight 40',
    'crud.field.freight40HC.title': 'Price freight  40\'HC',
    'common.errorEmptyFields': 'Check that all fields are filled in',

    'filter.calculation.currencies': 'Currencies:',
    'filter.calculation.sort': 'Sort by:',
    'common.addRow': 'Add row',
    'tableComponent.contextMenu.cut': 'Cut',
    'tableComponent.contextMenu.copy': 'Copy',
    'tableComponent.contextMenu.paste': 'Paste',
    'tableComponent.contextMenu.deleteRow': 'Delete',
    'tableComponent.contextMenu.deleteRows': 'Delete rows',
    'tableComponent.contextMenu.insertRowBellow': 'Insert row bellow',
    'tableComponent.contextMenu.duplicateRow': 'Duplicate',
    'tableComponent.contextMenu.duplicateRows': 'Duplicate rows',
    'crud.form.table.add': 'Add from table',
    'crud.form.table.edit': 'Edit like in Excel',
    'common.selectDate': 'Select date',
    'tableComponent.confirm.cancelChages': 'The changes won\'t be saved.',

    'crud.form.add.table.title': 'Tariffs entry',
    'crud.form.edit.table.title': 'Edit tariffs',

    'tracking.fields.error.dateFactError': 'The selected date cannot be earlier than the planned cargo readiness date',
    'tracking.fields.error.datePlanError': 'The selected cargo readiness date cannot be earlier than the order creation date',
    'templates.documents.type.APPLICATION_TRANSPORTATION':'Shipping instruction',
    "crudField.internationalCompany.label":"International",
    "crud.field.countryFromId.title":"Country from",
    "crud.field.countryToId.title":"Country to",
    "htmlEditor.undo":"Undo",
    "htmlEditor.bold":"Bold",
    "htmlEditor.italic":"Italic",
    "htmlEditor.strikeThrough":"Strike through",
    "htmlEditor.link":"Link",
    "htmlEditor.clearFormatting":"Clear formatting",
    "settingsEmail.emailSender":"Email for reply",
    "settingsEmail.nameSender":"Sender's name",
    "settingsEmail.footerRu":"Signature at the bottom of the email (Russian language)",
    "settingsEmail.footerEn":"Signature at the bottom of the email (English language)",
    "settingsEmail.hideAdvancedSettings":"Advanced settings",
    "settingsEmail.showAdvancedSettings":"Advanced settings",
    "settingsEmail.styles":"More styles",
    "settingsEmail.styles.help":"You can find out about supported CSS style properties by the <a href='https://developers.google.com/gmail/design/css#supported_css'>link</a>.",
    "settingsEmail.save":"Save",
    "settingsEmail.sendTestEmail":"Send test email",
    "settingsEmail.showTestEmail":"Preview",
    "crud.tab.organization.emailSettings":"Email settings",
    "settingsEmail.styles.warning":"This section is intended for additional customization of email styles.",
    "crud.field.nameEn.title":"Name (English)",
    "crud.field.city.title":"City",
    "crud.field.origin.title":"Origin",
    "crud.field.product.title":"Product",
    "crud.field.minPrice.title":"Min. cost",
    "crud.field.terminalProcessing.title":"Terminal processing",
    "crudForm.field.terminalProcessing":"Terminal processing",
    "crud.field.surcharges.title":"Surcharges",
    "table.tariffs.fillAuto":"Enter automatically",
    "tableTariffs.enterRates":"To be able to save, enter at least one rate",
    "common.reset":"Reset",
    "common.removeAll":"Clear all",
    "airTariffWeights.modal.title":"Add weights",
    "crud.field.maxClients.title":"Max count of clients",
    "users.usageClients":"Existing client users",
    "files.type.logo_inverted":"Logo (menu)",
    "files.type.FAVICON":"Favicon",
  }
}
