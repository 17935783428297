/* eslint-disable */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Row, Col, Button } from 'reactstrap';
import FontAwesome from 'react-fontawesome';
import { isEmpty } from 'lodash';
import { observer } from 'mobx-react';
import AppStore from './../stores/appStore';
import UserStore from './../stores/userStore';
import Header from '../components/common/Header';
import Footer from '../components/common/Footer';
import Dialogs from '../components/common/Dialogs';
import {
  getLangInStorage,
  setLangInStorage,
  getCookie, translate
} from '../utils/utils';
import MainMenu from '../components/ui/MainMenu';
import * as URL from '../constants/routes';
import { Modal } from '../components/ui/Modal';
import OrganizationProfilesStore from '../stores/organizationProfilesStore';
import OrganizationStore from '../stores/organizationStore';
import { FlashStatus } from '../components/common/Statuses';
import { Preloader } from '../components/ui/Preloader';
import ModalCurrentUser from '../components/ui/ModalCurrentUser';
import ModalContactUs from '../components/ui/ModalContactUs';
import ModalWizardSubscription from '../components/ui/ModalWizardSubscription';
import WidgetFooter from '../components/common/WidgetFooter';
import WidgetHeader from '../components/common/WidgetHeader';
import ModalAuth from '../components/Auth/ModalAuth';
import {pushAnalyticalEvent, metricsEvents} from '../utils/analyticsMetrics';

import './../assets/styles/global.css';
import {Helmet} from "react-helmet";
import DictionaryService from '../services/dictionaryService';
import OrganizationService from '../services/organizationService';
import { toJS } from 'mobx';
import { AUTH_LOGIN, AUTH_REGISTER, WIZARD } from '../constants/routes';
import config from '../config';
import axios from 'axios';
import { USER_LANG } from '../constants/global';
axios.defaults.baseURL = config.endpointSSO;
const PUBLIC_PAGES = [
  URL.AUTH_LOGIN+"/",
  URL.AUTH_PASSWORD_RECOVERY+"/",
  URL.AUTH_REGISTER+"/",
  URL.WIZARD+"/",
  // URL.AGREEMENT,
];

const doNotShowOfferIntervalInDays = 14;
const subscriptionNewDateKey = 'newSubscriptionOfferDate';
let timer = null;
const timerPeriodInSeconds = 100;
let interval=null;
const ACCESS_TOKEN_KEY = 'access_token';
const REFRESH_TOKEN_KEY = 'refresh_token';

export default class Page extends Component {
  state = {
    loading: true,
    userMenuIsOpen: false,
    notificationMenuIsOpen: false,
    isShowContactUsModal: false,
    isShowCurrentUserModal: false,
    isShowCookieInfo: false,
    isWizardSubscribeModalOpen: false,
    doNotOfferSubscription: (() => {
      const nextSubscriptionDateValue = localStorage.getItem(subscriptionNewDateKey);
      if(nextSubscriptionDateValue){
        return (nextSubscriptionDateValue * 1 - new Date().getTime()) > 0;
      }
      return false;
    })(),
  };


  async getCode(){
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    if (code) {
      let payload = new FormData();
      payload.append('grant_type', 'authorization_code');
      payload.append('code', code);
      payload.append('redirect_uri', config.redirectURI);
      payload.append('client_id', config.clientId);

      const r = await axios.post('/oauth2/token', payload, {
          headers: {
            'Content-type': 'application/url-form-encoded',
            'Authorization': config.clientAuth
          }
        }
      ).then(async response => {
        if (response.status === 200) {
          // получаем токены, кладем access token в LocalStorage
          if (response?.data) {
            let testTokenData = new FormData();
            testTokenData.append("token", response.data[ACCESS_TOKEN_KEY]);
            await axios.post("/oauth2/token-info", testTokenData, {
              headers: {
                'Content-type': 'application/url-form-encoded',
                'Authorization': config.clientAuth
              }
            }).then(r => {
              console.log("response test token: ", r.data)
              if (r.data.active === true) {
                window.localStorage.setItem('token', response.data[ACCESS_TOKEN_KEY]);
                window.localStorage.setItem('refreshToken', response.data[REFRESH_TOKEN_KEY]);
                let params = new URLSearchParams(window.location.search);
                window.history.replaceState({}, document.title, `${window.location.pathname}?token=${response.data[ACCESS_TOKEN_KEY]}&refreshToken=${response.data[REFRESH_TOKEN_KEY]}${params.has("redirect") ? '&redirect=true' : ''}`);
                //window.location = "/book/wizard?token="+response.data[ACCESS_TOKEN_KEY]
                return "ok";
              }
              return "ok"
            }).catch(e => {
              console.error(e)
              //window.location = "/book/wizard"
              return "fail"
            })
          }
        }
        return "fail"
      }).catch(e => {
        console.error(e)
        return "fail"
        //window.location = "/book/wizard"
      })
      console.log("RE:", r)
      return r;
    }
  }

  async UNSAFE_componentWillMount() {
    const { scope, history, widget, isSEO } = this.props;
    let params = new URLSearchParams(window.location.search);

    const isWidget = params.has("isWidget");
    console.log("HAS PARAMS: TOKEN", params.has("token"), "WIDGET: ", isWidget);
    if(isWidget===false) {
      await this.getCode();
      if (params.has("token") === true && !isWidget) {
        console.log("TOKEN: ", params.get("token"))
        localStorage.setItem("token", params.get("token"))
        if(params.has("refreshToken")){
          localStorage.setItem("refreshToken", params.get("refreshToken"))
        }
        console.log("NR: ", `${window.location.host}${window.location.pathname}${params.has("redirect") ? '?redirect=true' : ''}`)
        await UserStore.update(true)
        if (window.location.host.includes("localhost") ||window.location.host.split(".").length === 3)
          window.history.replaceState({}, document.title, `${window.location.pathname}${params.has("redirect") ? '?redirect=true' : ''}`);
        //window.location = `${window.location.pathname}${params.has("redirect") ? '?redirect=true' : ''}`
        //history.push(`${window.location.pathname}${params.has("redirect") ? '?redirect=true' : ''}`)
        //window.location.reload();
      }
      if (params.has("redirect") && !isWidget) {
        await UserStore.logout(false);
        //history.push(WIZARD);
        //window.location = WIZARD
      }
    }
    AppStore.setHistory(history);
    params = new URLSearchParams(window.location.search);
    const user = await UserStore.update(params.has("token"));
    console.log("USER DATA: ", user)
    /*if(UserStore.isGuest() && !isWidget &&  window.location.host.split(".").length === 3) {
        window.location.reload();
    }*/
    const falseValue = (history.location.pathname.split('/').includes('orders') && !history.location.pathname.split('/').includes('admin') && UserStore.isOperator()) ? true : AppStore.isVisibleAccessDeniedMessage;
    if(isWidget===false) {
      let arrayHost = window.location.host.split(".");
      let domainV3 = undefined;


      if (window.location.host.includes("localhost") === false && arrayHost.length === 3 && !isWidget) {
        let checkDomainExist = toJS(await OrganizationService.checkDomain(arrayHost[0]));
        if (!!checkDomainExist === false) {
          let newHost = `https://${arrayHost[1]}.${arrayHost[2]}`
          let newRoute = `${newHost}${window.location.pathname}?redirect=true`
          console.log("ROUTE NEW:", newRoute)
          //window.history.replaceState({}, document.title, newRoute);
          window.location.assign(newRoute)
        }
      }
      if (window.location.host.includes("localhost") === false && UserStore.isGuest() && [AUTH_LOGIN, AUTH_REGISTER].includes(window.location.pathname) && arrayHost.length === 3 && !isWidget) {
        let newHost = `https://${arrayHost[1]}.${arrayHost[2]}`
        let newRoute = `${newHost}${window.location.pathname}?redirect=true${localStorage.getItem("token") ? '&token=' + localStorage.getItem("token") : ''}${localStorage.getItem("refreshToken") ? '&refreshToken=' + localStorage.getItem("refreshToken") : ''}`
        console.log("ROUTE NEW:", newRoute)
        //window.history.replaceState({}, document.title, newRoute);
        window.location.assign(newRoute)
      }
      if (user.organization && user.organization.domain && !isWidget) {
        domainV3 = user.organization.domain
        console.log("DOMAIN V3 1: ", domainV3)
      }
      if (user.organization && user.organization.parent && !UserStore.isGuest() && !isWidget) {
        console.log("USER: ", user)
        const domainResp = toJS(await OrganizationService.getOrganizationParentDomain(user.organization.parent));
        domainV3 = domainResp.data
        console.log("DOMAIN V3 2: ", domainV3)
      }
      if (arrayHost.length > 1 && domainV3 && !isWidget) {
        if (arrayHost.length !== 3 && window.location.host.includes("localhost") === false) {
          let newHost = `https://${domainV3}.${arrayHost[0]}.${arrayHost[1]}`
          let newRoute = `${newHost}${history.location.pathname}${localStorage.getItem("token") ? '?token=' + localStorage.getItem("token") : ''}${localStorage.getItem("refreshToken") ? '&refreshToken=' + localStorage.getItem("refreshToken") : ''}`
          console.log("ROUTE NEW:", newRoute)
          //window.history.replaceState({}, document.title, newRoute);
          window.location.assign(newRoute)
        } else if (arrayHost.length === 3 && window.location.host.includes("localhost") === false) {
          let newHost = `https://${domainV3}.${arrayHost[1]}.${arrayHost[2]}`
          if (arrayHost[0] !== domainV3) {
            let newRoute = `${newHost}${history.location.pathname}?redirect=true`
            console.log("ROUTE NEW:", newRoute)
            //window.history.pushState({}, document.title, newRoute);
            window.location.assign(newRoute)
          }
        }
      }
      if(localStorage.getItem("redirectTo")!==null){
        const redirectTo = localStorage.getItem("redirectTo");
        localStorage.removeItem("redirectTo");
        history.push(redirectTo);
      }
    }
    if (!widget && (AppStore.isVisibleAccessDeniedMessage || falseValue) && !PUBLIC_PAGES.includes(history.location.pathname) && !isSEO && !history.location.pathname.includes("/public")) {
      if (true) {
        const role = history.location.pathname.split('/')[1];
        // eslint-disable-next-line no-nested-ternary
        const newRole = UserStore.isOperator() ? 'admin' : UserStore.isPartner() ? 'partner' : UserStore.isClient() ? 'client' : 'guest';
        const redirectRoleURL = history.location.pathname.replace(role, newRole);
        history.push(redirectRoleURL);
      } else {
        console.error('Page not found');
        // history.push(URL.AUTH_LOGIN);
      }
    }

    OrganizationStore.setExpiredTariffs([]);

    switch (scope) {
      case 'guest':
        this.setState({
          loading: false,
        });
        break;

      default: {
        if (!user) {
          if (this.props.widget) {
            window.location.reload(true);
          } else if (!PUBLIC_PAGES.includes(history.location.pathname)) {
            history.push(URL.AUTH_LOGIN);
          }
        } else {
          if (!UserStore.isClient() && !UserStore.isGuest()) {
            setTimeout(() => {
              OrganizationStore.reloadExpiredTariffs();
            }, 3000)

          }

          this.setState({ loading: false });
        }
      }
    }

    const isFirstOpen = localStorage.getItem('isFirstOpen');

    if (isEmpty(isFirstOpen)) {
      this.setState({
        //включение cookie,пока отключаем
        isShowCookieInfo: false,
      });
    }
    await AppStore.checkTokenValidation();
    interval = setInterval(() => {
      if (window.localStorage.getItem('refreshToken') !== null) {
        let payload = new FormData();
        payload.append('grant_type', 'refresh_token');
        payload.append('refresh_token', window.localStorage.getItem('refreshToken'));

        return axios.post('/oauth2/token', payload, {
            headers: {
              'Content-type': 'application/url-form-encoded',
              'Authorization': config.clientAuth,
            },
          },
        ).then(response => {
          window.localStorage.setItem('token', response.data[ACCESS_TOKEN_KEY]);
          window.localStorage.setItem('refreshToken', response.data[REFRESH_TOKEN_KEY]);
        }).catch(err => {
          console.error('Error refresh tokens: ' + err);
        });
      }
    }, 1000 * 10);

  }

  componentDidMount() {
    if (this.props.history.location.search) {
      this.setLangFromSearch();
    }

    timer = setTimeout(() => {
      this.setState({isWizardSubscribeModalOpen: true});
    }, 1000 * timerPeriodInSeconds);

    const isLeftMenuOpen = localStorage.getItem('isLeftMenuOpen');

    // смена языка гугл апи при смене языка
    const langCode = AppStore.userLang || getLangInStorage();

    this.renderGoogleApi(langCode);

    if (isLeftMenuOpen === null) {
      AppStore.changeStatusLeftMenu(false);
    } else if (isLeftMenuOpen === 'false') {
      AppStore.changeStatusLeftMenu(false);
    } else if (isLeftMenuOpen === 'true') {
      AppStore.changeStatusLeftMenu(true);
    }
  }

  componentWillUnmount() {
    clearTimeout(timer);
    if(interval!==null)
      clearInterval(interval)
  }

  setLangFromSearch() {
    const { search } = this.props.history.location;
    const storedLang = getCookie('lang');
    let lang = storedLang || 'ru';

    const getParamsArr = search.replace('?', '').split('&');
    getParamsArr.forEach((param) => {
      const str = param.split('=');

      if (str[0] === 'language' && str[1] === 'en') {
        lang = 'en';
      }
    });
    if(localStorage.getItem(USER_LANG)===null||localStorage.getItem(USER_LANG)===undefined){
      setLangInStorage(lang);
    }
  }

  @observer
  renderGoogleApi(langCode) {
    AppStore.setGoogleApiMount(false);
    delete window.google;

    const lang = langCode === 'zh' ? 'zh-CN' : langCode;
    const script = document.body.querySelector('script[data-name="googleApi"]');
    const newScript = document.createElement('script');
    newScript.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyDqZd6qeFCLCXk23fg0s7SzUFJkEDhDpQA&libraries=places&language=${lang}`;
    newScript.setAttribute('data-name', 'googleApi');

    if (script) {
      document.body.replaceChild(newScript, script);
    } else {
      document.body.appendChild(newScript);
    }

    setTimeout(() => {
      AppStore.setGoogleApiMount(true);
    }, 300);
  }

  changeGoogleApi = (langCode) => {
    this.renderGoogleApi(langCode);

    if (this.props.changeLangCallback && AppStore.userLang !== langCode) {
      this.props.changeLangCallback(langCode);
    }
  };

  toggleProp = (propName) => {
    this.setState({
      [propName]: !this.state[propName],
    });
  };

  handleCloseWidget() {
    window.parent.postMessage('[wizard] exit', '*');
  }

  handleClosingWidget = () => {
    this.setState({
      isWizardSubscribeModalOpen: true,
    });
  };

  onSubscribeModalClose = (isBtnClicked, withSubscription) => {
    const newState = {
      isWizardSubscribeModalOpen: false,
    };

    if(isBtnClicked){
      const now = new Date();
      const nextDate = now.setDate(now.getDate() + ((withSubscription? 365: 1) * doNotShowOfferIntervalInDays));
      localStorage.setItem(subscriptionNewDateKey, nextDate);
    }

    this.setState(newState);
  };

  // renderAccessDeniedMessage() {
  //   return (
  //     <div className="content">
  //       <Row className="d-block">
  //         <Col xs={12}>
  //           <Row className="page-header">
  //             <h1 className="text-center">
  //               <FormattedMessage id="page.accessDenied.text" /> {this.props.location.pathname}
  //             </h1>
  //           </Row>
  //           <Row className="text-center">
  //             {AppStore.hasUrlHistory() ? (
  //               <Link
  //                 className="btn btn-link"
  //                 to="/"
  //                 onClick={() => {
  //                   this.props.history.goBack();
  //                   AppStore.hideAccessDeniedMessage();
  //                 }}
  //               >
  //                 <FormattedMessage id="page.accessDenied.prevLinkText" />
  //               </Link>
  //             ) : null}
  //             <Link
  //               className="btn btn-link"
  //               to={URL.AUTH_LOGIN}
  //               onClick={() => AppStore.hideAccessDeniedMessage()}
  //             >
  //               <FormattedMessage id="page.accessDenied.loginLinkText" />
  //             </Link>
  //           </Row>
  //         </Col>
  //       </Row>
  //     </div>
  //   );
  // }

  toggleUserMenu = () => {
    this.setState({
      userMenuIsOpen: !this.state.userMenuIsOpen,
    });
  };
  toggleNotificationMenu = () => {
    this.setState({
      notificationMenuIsOpen: !this.state.notificationMenuIsOpen,
    });
  };

  onContactUs = () => {
    this.setState({
      isShowContactUsModal: true,
    });
  };

  toggleModalCurrentUser = ({ isShow = !this.state.isShowCurrentUserModal } = {}) => {
    this.setState({ isShowCurrentUserModal: isShow });
  };

  render() {
    const { isPreloaderShow, pageTitle, widget } = this.props;

    if (this.state.loading || !AppStore.isGoogleMapsApiMount) {
      return <div className="overlay" />;
    }

    const isPublicPage = PUBLIC_PAGES.includes(this.props.history.location.pathname);

    const isLeftMenuOpen = UserStore.user && this.props.scope === 'user' && AppStore.isLeftMenuOpen;

    return (
      <div className={`${widget ? 'main-layout main-layout_widget' : 'main-layout'} ${this.props.scope}`}>
        <Helmet>
          <title>{this.props.seoTitle}</title>
          <meta name="description" content={this.props.seoDescription} />
        </Helmet>
        <Preloader isShow={isPreloaderShow} />
        <FlashStatus />
        <Modal
          top
          isShow={!AppStore.isServerAvailable}
          title={<FormattedMessage id="common.error" />}
        >
          <Row>
            <Col xs={12} className="text-center mb-3">
              <FontAwesome
                name="exclamation-triangle"
                className="fa-2x text-danger"
              />
            </Col>
            <Col xs={12} className="text-center mt-3">
              <Button onClick={() => window.location.reload(true)}>
                <FontAwesome
                  name="refresh"
                  className="mr-2"
                />
                <FormattedMessage id="service.reloadThePage" />
              </Button>
            </Col>
          </Row>
        </Modal>
        <ModalAuth
          {...this.props}
          //isShown={AppStore.isVisibleAuthModalDialog && !isPublicPage}
          //hint={AppStore.getHintAuthModalDialog}
          onClose={() => AppStore.closeAuthModalDialog()}
        />
        <ModalCurrentUser
          isShow={this.state.isShowCurrentUserModal}
          onClose={() => this.toggleProp('isShowCurrentUserModal')}
        />
        {/*<ModalContactUs
          isShown={this.state.isShowContactUsModal}
          onClose={() => this.toggleProp('isShowContactUsModal')}
        />
        {!widget && <ModalWizardSubscription
          isShown={this.state.isWizardSubscribeModalOpen && !this.state.doNotOfferSubscription}
          onClose={this.onSubscribeModalClose}
        />}*/}
        { widget ? (
          <WidgetHeader onClose={this.handleCloseWidget} onClosing={this.handleClosingWidget} />
        ) : (
          <Header
            history={this.props.history}
            isMenuOpen={this.state.userMenuIsOpen}
            isNotificationMenuOpen={this.state.notificationMenuIsOpen}
            isLeftMenuOpen={isLeftMenuOpen}
            changeGoogleApi={this.changeGoogleApi}
            toggleModalCurrentUser={() => this.toggleModalCurrentUser()}
            toggleUserMenu={() => this.toggleUserMenu()}
            toggleNotificationMenu={() => this.toggleNotificationMenu()}
            userIsAuthenticated={UserStore.user && this.props.scope === 'user'}
            onContactUs={() => this.onContactUs()}
          />
        )}

        { (!widget && UserStore.user && this.props.scope === 'user') &&
          <MainMenu
            organizationProfiles={OrganizationProfilesStore.currentUserOrganizationProfiles}
            expiredTariffs={OrganizationStore.expiredAllTariffs}
            isOpen={AppStore.isLeftMenuOpen}
            showContactModal={() => this.toggleProp('isShowContactUsModal')}
            changeGoogleApi={this.changeGoogleApi}
          />
        }

        <div
          className={widget
            ? 'main'
            : `main ${(AppStore.isLeftMenuOpen && !UserStore.isGuest()) ? 'menu-open' : 'menu-close'}`
          }
        >
          <SetTranslate />
          <div className="container">
            {/*{AppStore.isVisibleAccessDeniedMessage ? (*/}
            {/*this.renderAccessDeniedMessage()*/}
            {/*) : (*/}
            <div className="content">
              {pageTitle && <div className="page-header"><h1>{pageTitle}</h1></div>}
              {this.props.children}
            </div>
            {/* )} */}
          </div>

          {this.props.foot}

          <Dialogs />
        </div>
        { !widget && this.state.isShowCookieInfo &&
          <div className="cookieInfo py-3">
            <span
              className="fa fa-close cookieInfo__close"
              onClick={() => {
                this.setState({ isShowCookieInfo: false });
                localStorage.setItem('isFirstOpen', 'false');
              }}
            />
            <FormattedMessage id="common.siteUsesCookie" />
          </div>
        }
        {widget ? <WidgetFooter /> : <Footer isMenuOpen={isLeftMenuOpen} />}
      </div>
    );
  }
}

Page.propTypes = {
  pageTitle: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.object]),
  foot: PropTypes.oneOfType([PropTypes.node, PropTypes.object]),
  history: PropTypes.object,
  location: PropTypes.object,
  scope: PropTypes.string.isRequired,
  isPreloaderShow: PropTypes.bool,
  changeLangCallback: PropTypes.func,
  widget: PropTypes.bool,
  terms: PropTypes.string,
  // eslint-disable-next-line react/no-unused-prop-types
  isSEO: PropTypes.bool,
  seoTitle: PropTypes.string,
  seoDescription: PropTypes.string,
};

Page.defaultProps = {
  widget: false,
  isSEO: false,
  terms: '',
  seoTitle: translate('form.wizard.seo.title'),
  seoDescription:  translate('form.wizard.seo.description'),
};

// Ужасный костыль.
const SetTranslate = injectIntl((props) => {
  const { intl } = props;
  AppStore.translate = intl.formatMessage;

  return null;
});
