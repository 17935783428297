import HttpProvider from './httpProvider';

import {API} from '../constants/api';

class DictionaryService {
  store = {};

  constructor() {
    this.http = HttpProvider;
  }

  async getCityes(params, force = false) {
    if (!params.language || !params.country) {
      return new Promise((resolve) => {
        resolve([]);
      });
    }
    return await this.getDictionary(API.GOOGLE.PROXY(params), force)
  }
  async getCityesPopular(params, force = false) {
    if (!params.language || !params.country) {
      return new Promise((resolve) => {
        resolve([]);
      });
    }

    if(params.hubToId===undefined && params.hubFromId === undefined){
      return await this.getDictionary(API.GOOGLE.PROXY_POPULAR(params), force)
    }

    if(params.hubFromId!==null && params.hubFromId!==undefined && params.hubToId === undefined){
      return await this.getDictionary(API.GOOGLE.PROXY_POPULAR_HUB_FROM(params), force)
    }
    if(params.hubToId!==null&& params.hubToId!==undefined && params.hubFromId === undefined){
      return await this.getDictionary(API.GOOGLE.PROXY_POPULAR_HUB_TO(params), force)
    }
    return await this.getDictionary(API.GOOGLE.PROXY_POPULAR(params), force)
  }
  async getPopular(params, force = false){
    return await this.getDictionary(API.DICTIONARIES.POPULAR_DESTINATIONS(params), force)
  }

  async getContainerTypes(params, force = true) {
    return await this.getDictionary(API.DICTIONARIES.CONTAINER_TYPES(params), force);
  }

  async getOrderTemplates(params, force = false){
    return await this.getDictionary(API.DICTIONARIES.GET_ORDER_TEMPLATES(params), force)
  }

  async getContainerTypesByCountries(params, force = false) {
    return await this.getDictionary(API.DICTIONARIES.CONTAINER_TYPES_BY_COUNTRY(params), force);
  }

  async getCargoContainerTypes(kind, force = false) {
    return await this.getDictionary(API.DICTIONARIES.CARGO_CONTAINER_TYPES({ kind }), force);
  }

  async getCurrencies(params, force = true) {
    return await this.getDictionary(API.DICTIONARIES.CURRENCIES(params), force);
  }

  async getDocumentTypes(params, force = false) {
    return await this.getDictionary(API.DICTIONARIES.DOCUMENT_TYPES(params), force);
  }

  async getGeneralTypes(force = true) {
    return await this.getDictionary(API.DICTIONARIES.GENERAL_TYPES, force);
  }

  async getLanguages(force = false) {
    return await this.getDictionary(API.DICTIONARIES.LANGUAGES, force);
  }

  async getPalletTypes(force = true) {
    return await this.getDictionary(API.DICTIONARIES.PALLET_TYPES, force);
  }

  async getSeaLines(params, force = false) {
    return await this.getDictionary(API.DICTIONARIES.SEA_LINES(params), force);
  }

  async getSeaPortHubs(params, force = false) {
    return await this.getDictionary(API.DICTIONARIES.SEA_PORT_HUBS(params), force);
  }

  async getSeaPortHubsById(id, force = false) {
    return await this.getDictionary(API.DICTIONARIES.SEA_PORT_HUBS_BY_ID({ id }), force);
  }

  async getSeaPortHubsByCity(params, force = false) {
    return await this.getDictionary(API.DICTIONARIES.SEA_PORT_HUBS_BY_CITY(params), force);
  }
  async getSeaPortHubsByCityPopular(params, force = false) {
    return await this.getDictionary(API.DICTIONARIES.SEA_PORT_HUBS_BY_CITY_POPULAR(params), force);
  }

  async getSeaPortHubsByCityName(params, force = false) {
    return await this.getDictionary(API.DICTIONARIES.SEA_PORT_HUBS_BY_CITY_NAME(params), force);
  }
  async getSeaPortHubsByCityNamePopular(params, force = false) {
    return await this.getDictionary(API.DICTIONARIES.SEA_PORT_HUBS_BY_CITY_NAME_POPULAR(params), force);
  }

  async getAirportHubsByCityName(params, force = false) {
    return await this.getDictionary(API.DICTIONARIES.AIR_PORT_HUBS_BY_CITY_NAME(params), force);
  }
  async getAirportHubsByCityNamePopular(params, force = false) {
    return await this.getDictionary(API.DICTIONARIES.AIR_PORT_HUBS_BY_CITY_NAME_POPULAR(params), force);
  }

  async getRailwayHubsByCityName(params, force = false) {
    return await this.getDictionary(API.DICTIONARIES.RAILWAY_HUBS_BY_CITY_NAME(params), force);
  }
  async getRailwayHubsByCityNamePopular(params, force = false) {
    return await this.getDictionary(API.DICTIONARIES.RAILWAY_HUBS_BY_CITY_NAME_POPULAR(params), force);
  }

  async getTransportHubsById(id, force = false) {
    return await this.getDictionary(API.DICTIONARIES.TRANSPORT_HUBS_BY_ID({ id }), force);
  }

  async getAirHubsById(id, force = false) {
    return await this.getDictionary(API.DICTIONARIES.AIR_HUBS_BY_ID({ id }), force);
  }

  async getAirportHubsByCity(params, force = false) {
    return await this.getDictionary(API.DICTIONARIES.AIR_PORT_HUBS_BY_CITY(params), force);
  }
  async getAirportHubsByCityPopular(params, force = false) {
    return await this.getDictionary(API.DICTIONARIES.AIR_PORT_HUBS_BY_CITY_POPULAR(params), force);
  }

  async getRailwayHubs(params, force = false) {
    return await this.getDictionary(API.DICTIONARIES.RAILWAY_HUBS(params), force);
  }

  async getRailwayHubById(id, force = false) {
    return await this.getDictionary(API.DICTIONARIES.RAILWAY_HUBS_BY_ID({ id }), force);
  }

  async getRailwayHubsByCity(params, force = false) {
    return await this.getDictionary(API.DICTIONARIES.RAILWAY_HUBS_BY_CITY(params), force);
  }
  async getRailwayHubsByCityPopular(params, force = false) {
    return await this.getDictionary(API.DICTIONARIES.RAILWAY_HUBS_BY_CITY_POPULAR(params), force);
  }

  async getRailwayServiceCategories(params, force = false) {
    return await this.getDictionary(API.DICTIONARIES.RAILWAY_SERVICE_CATEGORIES(params), force);
  }

  async getAllHubsByCityId(params, force = false) {
    return await this.getDictionary(API.DICTIONARIES.ALL_HUBS_BY_CITY_ID(params), force);
  }

  async getAllHubsByAddress(data) {
    const url = API.DICTIONARIES.ALL_HUBS_BY_ADDRESS();
    return await this.http.post(url, data).then(res => res.data);
  }

  async getSizeUnits(force = true) {
    return await this.getDictionary(API.DICTIONARIES.SIZE_UNITS, force);
  }

  async getWeightUnits(force = true) {
    return await this.getDictionary(API.DICTIONARIES.WEIGHT_UNITS, force);
  }

  async getCountries(params, force = true) {
    return await this.getDictionary(API.GEO.COUNTRIES(params), force); //?offset=0&limit=500
  }

  async getCountriesByRegion(params, force = false) {
    return await this.getDictionary(API.GEO.COUNTRIES_BY_REGIONS_WORLD(params), force);
  }

  async getWorldParts(force = false) {
    // TODO: Сделать отдельный запрос для частей света
    return await this.getDictionary(`${API.BASE('dictionaries')}/geo?type=WORLD_PART`, force).then(response => response.items);
  }

  async getWorldRegions(force = false) {
    return await this.getDictionary(API.GEO.REGIONS_WORLD, force);
  }

  async getCities(params, force = false) {
    return await this.getDictionary(API.GEO.CITIES(params), force);
  }

  async getCitiesExtended(params, force = false) {
    return await this.getDictionary(API.GEO.CITIES_EXTENDED(params), force);
  }

  async getRegions(params, force = false) {
    return await this.getDictionary(API.GEO.REGIONS(params), force);
  }

  async getAllRegions(params, force = false) {
    return await this.getDictionary(API.GEO.ALL_REGIONS(params), force);
  }

  async getAllCities(params, force = false) {
    return await this.getDictionary(API.GEO.ALL_CITIES(params), force);
  }


  async getGeoObjectById(id, force = false) {
    return await this.getDictionary(API.GEO.GET_GEO_OBJECT_BY_ID({ id }), force);
  }

  async getGeoObjectsByParentId(id, force = false) {
    return await this.getDictionary(API.GEO.GET_GEO_OBJECT_BY_PARENT_ID({ id }), force);
  }

  async getDisputedTerritories(force = false) {
    return await this.getDictionary(API.GEO.DISPUTED_TERRITORIES(), force);
  }

  async getAllPortServiceCategories(force = false) {
    return await this.getDictionary(API.DICTIONARIES.SERVICE_CATEGORIES, force);
  }

  async getPortServiceCategories(force = false) {
    return await this.getDictionary(API.DICTIONARIES.PORT_SERVICE_CATEGORIES, force);
  }

  async getDictionaryServices(params, force = false) {
    return await this.getDictionary(API.DICTIONARIES.DICTIONARY_SERVICES(params), force);
  }

  async getAllFreightSurcharges(params, force = false) {
    return await this.getDictionary(API.DICTIONARIES.FREIGHT_SURCHARGES(params), force);
  }

  async getContainerFreightSurcharges(params, force = false) {
    return await this.getDictionary(API.FREIGHT_CONTAINER.BASE(params), force);
  }

  async getContainerFreightSurchargesAll(params, force = true) {
    return await this.getDictionary(API.FREIGHT_CONTAINER.BASE_ALL(params), force);
  }

  async getGeneralFreightSurcharges(params, force = false) {
    return await this.getDictionary(API.FREIGHT_GENERAL.BASE(params), force);
  }

  async getContainerFreightSurchargesBySeaLine(params, force = false) {
    return await this.getDictionary(API.FREIGHT_CONTAINER.FREIGHT_SURCHARGES_BY_SEA_LINE(params), force);
  }

  async getGeneralFreightSurchargesBySeaLine(params, force = false) {
    return await this.getDictionary(API.FREIGHT_GENERAL.FREIGHT_SURCHARGES_BY_SEA_LINE(params), force);
  }

  async getContainerFreightSurchargesByTariff(params, force = false) {
    return await this.getDictionary(API.FREIGHT_CONTAINER.FREIGHT_SURCHARGES_BY_TARIFF(params), force);
  }

  async getGeneralFreightSurchargesByTariff(params, force = false) {
    return await this.getDictionary(API.FREIGHT_GENERAL.FREIGHT_SURCHARGES_BY_TARIFF(params), force);
  }

  async getTransportHubs(force = false) {
    return await this.getDictionary(API.DICTIONARIES.TRANSPORT_HUBS, force);
  }

  async getAllHubs(params, force = false) {
    return await this.getDictionary(API.DICTIONARIES.ALL_HUBS(params), force);
  }

  async getAllHubsWithChina(params, force = false) {
    return await this.getDictionary(API.DICTIONARIES.ALL_HUBS_WITH_CHINA(params), force);
  }
  async getAllHubsRussia(params, force = false) {
    return await this.getDictionary(API.DICTIONARIES.ALL_HUBS_Russia(params), force);
  }

  async getAllHubById(id, force = false) {
    return await this.getDictionary(API.DICTIONARIES.ALL_HUBS_ID({ id }), force);
  }

  async getAirHubs(params, force = false) {
    return await this.getDictionary(API.DICTIONARIES.AIR_HUBS(params), force);
  }

  async getAirHubsWithNoTerminals(params, forse = true) {
    return await this.getDictionary(API.DICTIONARIES.AIR_HUBS_HAS_NO_TERMINALS(params), forse);
  }

  async getAirlines(params, force = false) {
    return await this.getDictionary(API.DICTIONARIES.AVIA_COMPANIES(params), force);
  }

  async getAirportServices(params, force = false) {
    return await this.getDictionary(API.DICTIONARIES.AIRPORT_SERVICES(params), force);
  }

  async getServicesLand(params, force = false) {
    return await this.getDictionary(API.DICTIONARIES.SERVICE_LAND(params, force));
  }

  // TODO: Возможно необходимо сделать serviceServices
  async getServices(force = false) {
    return await this.getDictionary(API.SERVICES.SERVICES, force);
  }

  async getServiceCategories(force = false) {
    return await this.getDictionary(API.SERVICES.CATEGORIES, force);
  }

  async getServiceProfiles(force = false) {
    return await this.getDictionary(API.SERVICES.PROFILES, force);
  }

  async getAllServiceProfiles(force = false) {
    return await this.getDictionary(API.DICTIONARIES.SERVICE_PROFILES, force);
  }

  async getExternalApis(force = false) {
    return await this.getDictionary(API.EXTERNAL_API.BASE, force);
  }

  async getCustomsCategory(params, force = false) {
    return await this.getDictionary(API.DICTIONARIES.CUSTOMS_CATEGORY(params), force);
  }

  async getCustomsCategoryById(params, force = false) {
    return await this.getDictionary(API.DICTIONARIES.CUSTOMS_CATEGORY_BY_ID(params), force);
  }

  async getCustomsCategories(params, force = false) {
    return await this.getDictionary(API.DICTIONARIES.CUSTOMS_CATEGORIES(params), force);
  }

  async getAuditEventTypes(params, force = false) {
    return await this.getDictionary(API.DICTIONARIES.AUDIT_EVENT_TYPES(params), force);
  }

  async getAuditObjectTypes(params, force = false) {
    return await this.getDictionary(API.DICTIONARIES.AUDIT_OBJECT_TYPES(params), force);
  }

  async getTransportationTypes(params, force = false) {
    return await this.getDictionary(API.DICTIONARIES.TRANSPORTATION_TYPES(params), force);
  }

  async getForwarders(force = false) {
    return await this.getDictionary(API.DICTIONARIES.FORWARDERS(), force);
  }

  async getInterestTypes(params, force = false) {
    return await this.getDictionary(API.DICTIONARIES.INTEREST_TYPES(params), force);
  }

  async getInterestTypesById(params, force = false) {
    return await this.getDictionary(API.DICTIONARIES.INTEREST_TYPES_BY_ID(params), force);
  }

  async getRailwayOperators(params, force = false) {
    return await this.getDictionary(API.DICTIONARIES.RAILWAY_OPERATORS(params), force);
  }

  async getRailwayStations(params, force = false) {
    return await this.getDictionary(API.DICTIONARIES.RAILWAY_HUBS(params), force);
  }

  async getAirports(showVirtual, force = false) {
    return await this.getDictionary(API.DICTIONARIES.AIR_HUBS(showVirtual), force);
  }

  async getEvents(params, force = false) {
    return await this.getDictionary(API.DICTIONARIES.EVENTS(params), force);
  }

  async getStatuses(params, force = false) {
    return await this.getDictionary(API.DICTIONARIES.STATUSES(params), force);
  }

  async getTransportationRules(params, force = false) {
    return await this.getDictionary(API.DICTIONARIES.TRANSPORTATION_RULES(params), force);
  }

  async sendTransportationRule(data) {
    return await this.http.post(API.DICTIONARIES.SEND_TRANSPORTATION_RULE(), data);
  }

  async deleteTransportationRule(id) {
    return await this.http.remove(API.DICTIONARIES.DELETE_TRANSPORTATION_RULE({ id }));
  }

  async getAllUsers(params, force = false) {
    const url = API.USERS.BASE(params);
    if (this.store[url] && !force) {
      return this.store[url];
    }

    this.store[url] = await this.http.get(url, false).then(res => res.data.items);
    return this.store[url];
  }

  async getAllUsersFilter(params, force = false) {
    const url = API.USERS.BASE_EMAIL(params);
    return await this.http.get(url, false).then(res => res.data);
  }

  async getVehicleTypes(){
    return await this.http.get(API.DICTIONARIES.CONTAINER_TYPES({kind: "AUTO"}), false)
  }

  async resetUsageLimit(id){
    return await this.http.get(API.EXTERNAL_API.RESET_USAGE_LIMIT({id}), false)
  }
  async getOrderLocations(params,force=false){
    const url = API.DICTIONARIES.ORDER_LOCATIONS(params);
    return await this.http.get(url, false).then(res => res.data);
  }

  async getDictionary(url, force) {
    return await this.http.get(url, force).then(res => res.data || res);
  }

  async getExternalApisAsList(force = false) {
    return this.http.get(API.EXTERNAL_API.ALL(), force);
  }
}

export default new DictionaryService();
